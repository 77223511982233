
import {Location} from '../location';
import {copyFromJSON, fromJSON} from '../../utils/json-utils';

export const SessionEventStatus = {
    NOBODY_IS_LISTENING: 'nobody_is_listening',
    WAITING_FOR_PICKUP: 'waiting_for_pickup',
    IN_ATTENDANCE: 'in_attendance',
    SOLVED: 'solved',
    CANCELLED_BY_SESSION_ENDED: 'cancelled_by_session_ended',
    CANCELED_BY_ANSWER_UNDERSTOOD: 'canceled_by_answer_understood',
};

export class SessionEvent{
    constructor(eventClassName) {
        this.id = null;
        this.eventClassName = eventClassName;
        this.eventDescription = null;
        this.sessionId = null;
        this.channelId = null;
        this.location = null; //Location
        this.status = null; //SessionEventStatus
        this.createdAt = null;
        this.solvedAt = null;
        this.solutionError = null;
    }

    fromJSON(infoJSON) {
        copyFromJSON(this, infoJSON);
        this.location = fromJSON(Location, infoJSON.location);
    }

}