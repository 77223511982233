import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { green, blue, red } from '@material-ui/core/colors';
import Accordion from '@material-ui/core/Accordion';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Alert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AiOutlineCheck } from 'react-icons/ai'
import { FiPhoneCall} from 'react-icons/fi'
import { RiQuestionnaireLine, RiQuestionAnswerLine} from 'react-icons/ri'
import { VscError} from 'react-icons/vsc'

import {WatchedSessionKPIContext} from "../../pages/main/contexts/watched-sessions-kpi-context";
import {MainFilterContext} from "../../pages/main/contexts/main-filter-context";

const useStyles = makeStyles((theme) => ({
    root: {
        // width: '100%',
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'bold',
        color: '#5589FF',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: '#5589FF',
        textAlign: 'center',
    },
    greenAvatar: {
        color: green,
        backgroundColor: green[300],
        marginRight:16,
    },

    blueAvatar: {
        color: blue,
        backgroundColor: blue[300],
        marginRight:16,
    },

    redAvatar: {
        color: red,
        backgroundColor: red[300],
        marginRight:16,
    },

    avatarNumber: {
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 'bold',
        lineHeight: 1,
        textAlign: 'left',
    },
    avatarTitle: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 0.8,
    },

    kpiCell: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
}));

export default function DoormaiKpiPanel() {
    const classes = useStyles();
    const {watchedKPI, watchedErrorMsg,
        watchSessionKPI, unwatchSessionKPI} = React.useContext(WatchedSessionKPIContext);
    const {locationsFilter, sessionCreatedFilter} = React.useContext(MainFilterContext);

    React.useEffect( () => {
        console.log('DoormaiKPIPanel.watchSessionKPI', sessionCreatedFilter);
        watchSessionKPI(sessionCreatedFilter);
        return () => {
           unwatchSessionKPI();
        }
    }, [sessionCreatedFilter]);

    const handleAccordionChanged = (e, expanded) => {
        if (expanded) {
            watchSessionKPI(sessionCreatedFilter);
        } else {
            unwatchSessionKPI();
        }
    };

    //console.log('DoormaiKPIPanel.locationsFilter', locationsFilter);
    const KPIAllLocations = watchedKPI.sumAllLocationsKPI(locationsFilter);
    return (
        <div>
            {watchedErrorMsg && <Alert severity="error">{watchedErrorMsg}</Alert>}
            <Accordion defaultExpanded onChange={handleAccordionChanged}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                    id="panel-header"
                >
                    {/*<div className={classes.column}>*/}
                        <Typography className={classes.heading}>Indicadores de atendimento</Typography>
                    {/*</div>*/}
                </AccordionSummary>

                <AccordionDetails className={classes.details}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Box className={classes.kpiCell}>
                                <Avatar className={classes.blueAvatar}>
                                    <FiPhoneCall />
                                </Avatar>
                                <div>
                                    <Typography className={classes.avatarNumber}>{KPIAllLocations.totalSessions}</Typography>
                                    <Typography className={classes.avatarTitle}>Total</Typography>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box className={classes.kpiCell}>
                                <Avatar className={classes.greenAvatar}>
                                    <AiOutlineCheck />
                                </Avatar>
                                <div>
                                    <Typography className={classes.avatarNumber}>{KPIAllLocations.sessionsSuccess}</Typography>
                                    <Typography className={classes.avatarTitle}>Sucessos</Typography>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box className={classes.kpiCell}>
                                <Avatar className={classes.redAvatar}>
                                    <VscError />
                                </Avatar>
                                <div>
                                    <Typography className={classes.avatarNumber}>{KPIAllLocations.sessionsFailure}</Typography>
                                    <Typography className={classes.avatarTitle}>Falhas</Typography>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={2}>
                            <Box className={classes.kpiCell}>
                                <Avatar className={classes.greenAvatar}>
                                    <RiQuestionAnswerLine />
                                </Avatar>
                                <div>
                                    <Typography className={classes.avatarNumber}>{KPIAllLocations.userAnswers}</Typography>
                                    <Typography className={classes.avatarTitle}>Minhas respostas</Typography>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box className={classes.kpiCell}>
                                <Avatar className={classes.blueAvatar}>
                                    <RiQuestionnaireLine />
                                </Avatar>
                                <div>
                                    <Typography className={classes.avatarNumber}>{KPIAllLocations.totalEvents}</Typography>
                                    <Typography className={classes.avatarTitle}>Total de eventos</Typography>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}