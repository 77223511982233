
export const ParticipantType = {
    DWELLER: 'dweller',
    VISITOR: 'visitor',
    VISITOR_COMPANY: 'company',
    CARETAKER: 'zelador',
    ADMIN: 'sindico',
    OPERATOR: 'operator', //operador da central de portaria rempta
    UNKNOWN: 'unknown'
};

export class Participant {
    constructor(participantType) {
        this.type = participantType || ParticipantType.UNKNOWN;
        this.name = null;
        this.homeId = null;
        this.phone = null;
    }

    getTypeDescription() {
        return Participant.getTypeDescription(this.type);
    }

    getShortName() {
        return Participant.getShortName(this.name);
    }

    isIdentified() {
        switch (this.type) {
            case ParticipantType.DWELLER: return this.name || this.homeId;
            default: return this.name;
        }
    }

    static isParticipantfromCondo(pType) {
        return Participant.getFromCondoTypes().includes(pType);
    }

    static getTypeDescription(pType) {
        switch (pType) {
            case ParticipantType.DWELLER: return 'Morador';
            case ParticipantType.CARETAKER: return 'Zelador';
            case ParticipantType.ADMIN: return 'Síndico';
            case ParticipantType.VISITOR: return 'Visitante';
            case ParticipantType.VISITOR_COMPANY: return 'Entregador';
            case ParticipantType.OPERATOR: return 'Operador';
            case ParticipantType.UNKNOWN: return 'Quem está na portaria';
            default: throw new Error(`I don't know the description of this type: ${pType}`);
        }
    }

    static getShortName(fullName) {
        return fullName ? fullName.split(' ')[0] : '';
    }

    static getParticipantTypes(includesUnknown=false) {
        let result =  [
            ParticipantType.DWELLER,
            ParticipantType.VISITOR,
            ParticipantType.CARETAKER,
            ParticipantType.VISITOR_COMPANY,
            ParticipantType.ADMIN,
            ParticipantType.OPERATOR,
        ];
        if (includesUnknown)
            result.push(ParticipantType.UNKNOWN);
        return result;
    }

    static getFromCondoTypes() {
        let result =  [
            ParticipantType.DWELLER,
            ParticipantType.CARETAKER,
            ParticipantType.ADMIN,
        ];
        return result;
    }
}

