

export function fromJSON(ObjectClass, infoJSON) {
    if (infoJSON) {
        const result = new ObjectClass();
        if (result.fromJSON) {
            result.fromJSON(infoJSON);
            return result;
        } else
            return Object.assign(result, infoJSON);
    } else
        return null;
}

export function copyFromJSON(instanceObj, infoJSON) {
    if (infoJSON) {
        for (let key in infoJSON) {
            instanceObj[key] = infoJSON[key];
        }
    }
}