const {HttpResponse} = require('./http-response')

export class BadRequestResponse extends HttpResponse {
    constructor(msg, data) {
        super(msg, data);
        this.statusCode = BadRequestResponse.httpStatus;
        this.success = false;
    }

    static get httpStatus() {
        return 400;
    }


}
