
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { green } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
//import { AiOutlinePlus } from 'react-icons/ai';


import {WatchedStatus, WatchedSessionStatusContext} from '../../pages/main/contexts/watched-session-status-context';
import useWsServer from "../../hooks/use-ws-server";
import useUserToken from "../../hooks/use-user-token";
import SessionStatusAvatar from "../session-status-avatar/SessionStatusAvatar";
import CardInfoPlayer from "../card-info-player/CardInfoPlayer";
import CardInfoMenu from "../card-info-menu/CardInfoMenu";
import CardInfoFeedbacks from "../card-info-feedbacks/CardInfoFeedbacks";
import {CardInfoMode} from "../card-info-feedbacks/CardInfoFeedbacks";
import {MainFilterContext} from "../../pages/main/contexts/main-filter-context";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0.8,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    primaryListItem: {
        fontSize: theme.typography.pxToRem(12),
        color: green[500],
    },
    sessionDescription: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "bold",
    },
    sessionDetail: {
        fontSize: theme.typography.pxToRem(12),
    },
}));

const TabIndex = {
    IN_ATTENDANCE: 0,
    ENDED: 1,
};

export default function DoormaiSessionsPanel() {
    const [selectedTab, setSelectedTab] = React.useState(TabIndex.IN_ATTENDANCE);
    const {watchSessionStatus, unwatchSessionStatus,
        inAttendanceSessions, endedSessions} = React.useContext(WatchedSessionStatusContext);
    const [sessionCards, setSessionCards] = React.useState([]);
    const [selectedSessionId, setSelectedSessionId] = React.useState('');
    const [sessionsInAddFeedbackMode, setSessionsInAddFeedbackMode] = React.useState([]);
    const classes = useStyles();
    const {isSessionCreatedFilterToday, sessionCreatedFilter, locationsFilter} = React.useContext(MainFilterContext);
    const {hasUserAdminScope} = useUserToken();


    const handleSocketConnectionChanged = (connectionState) => {
        console.log('sessionsPanel.handleSocketConnectionChanged', connectionState);
        if (connectionState === 'openedAndValidated') {
            if (selectedTab === TabIndex.IN_ATTENDANCE) {
                watchSessionStatus(WatchedStatus.IN_ATTENDANCE, locationsFilter, sessionCreatedFilter);
            } else {
                watchSessionStatus(WatchedStatus.ENDED, locationsFilter, sessionCreatedFilter, 200);
            }
        }
    };
    useWsServer( {onSocketConnectionChanged: handleSocketConnectionChanged} );

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setSelectedSessionId('');
    };

    const handleItemClick = (sessionId) => {
        //console.log('SessionCardInfo.clicked', sessionId, selectedSessionId);
        setSelectedSessionId(sessionId);
    };

    const handleComment = (event, sessionId) => {
        event.preventDefault();
        event.stopPropagation();
        const newSessionsList = [...sessionsInAddFeedbackMode];
        newSessionsList.push(sessionId);
        setSessionsInAddFeedbackMode(newSessionsList);
    };
    const handleCommentSaved = (sessionId) => {
        const newSessionsList = sessionsInAddFeedbackMode.filter( sessId => sessId !== sessionId);
        setSessionsInAddFeedbackMode(newSessionsList);
    }

    React.useEffect( ()=> {
       if (selectedTab === TabIndex.IN_ATTENDANCE) {
           watchSessionStatus(WatchedStatus.IN_ATTENDANCE, locationsFilter, sessionCreatedFilter);
       } else {
           watchSessionStatus(WatchedStatus.ENDED, locationsFilter, sessionCreatedFilter, 200);
       }
       return () => {
           if (selectedTab === TabIndex.IN_ATTENDANCE) {
               unwatchSessionStatus();
           } else {
               unwatchSessionStatus();
           }
       }
    }, [selectedTab, locationsFilter, sessionCreatedFilter]);

    React.useEffect( ()=> {
        let newSessionCards = selectedTab === TabIndex.IN_ATTENDANCE ?
            inAttendanceSessions : endedSessions;
        setSessionCards( newSessionCards );
        //console.log('DoormaiSessionsPanel.session cards updated', newSessionCards);
    }, [inAttendanceSessions, endedSessions]);


    const sessionCardsItems = [];
    let sessionCard;
    //console.log('sessionCards', sessionCards);
    for (let a=0; a<sessionCards.length; a++) {
        sessionCard = sessionCards[a];
        const localTime = new Date(sessionCard.createdAt); //convert server/UTC format to local time zone
        const sessionCreatedTime = `${localTime.getHours()}:${String(localTime.getMinutes()).padStart(2, '0')}`;
        let sessionId = sessionCard.sessionId;
        const showCardMenu = hasUserAdminScope();
        const feedbackMode = sessionsInAddFeedbackMode.includes(sessionId) ? CardInfoMode.INSERT : CardInfoMode.READ_ONLY;
        sessionCardsItems.push( (
            <div key={sessionId}>
                <Divider component="li" />
                <ListItem key={sessionId} alignItems="flex-start" role={undefined} button
                          onClick={()=>handleItemClick(sessionId)}>
                    <ListItemText
                        primary={
                            <div>
                                <Typography
                                    component="span"
                                    className={classes.primaryListItem}>
                                    {`${sessionCard.location.name} às ${sessionCreatedTime}`}
                                </Typography>
                                {/*<div>*/}
                                    <Tooltip title="Dê seu feedback" style={{ float: 'right' }}>
                                        <Button variant="outlined" size="small"
                                                onClick={(e)=>handleComment(e,sessionId)}>
                                            Comentar
                                        </Button>
                                    </Tooltip>
                                {/*</div>*/}
                                {showCardMenu
                                    &&
                                    <CardInfoMenu sessionId={sessionCard.sessionId} logUrl={sessionCard.voximplantInfo.logUrl}/>
                                }
                            </div>
                        }
                        secondary={
                            <div>
                                <Typography
                                    component="span"
                                    className={classes.sessionDescription}>
                                    {sessionCard.contactReasonDescription}
                                </Typography>
                                <br/>
                                {sessionCard.callerTypeDescription &&
                                <Typography
                                    component="span"
                                    className={classes.sessionDetail}>
                                    {sessionCard.callerTypeDescription}: {sessionCard.callerName || 'Não informado'}
                                </Typography>}
                                <SessionStatusAvatar cardInfo={sessionCard}/>
                                { selectedSessionId === sessionId
                                    && <CardInfoPlayer cardInfo={sessionCard}/>
                                }
                                <CardInfoFeedbacks cardInfo={sessionCard}
                                                   mode={feedbackMode} onSaveCancel={handleCommentSaved}/>
                            </div>
                        }
                    />
                </ListItem>
            </div>
        ));
    }

    return (
        <Paper>
            <Tabs variant="fullWidth"
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
            >
                <Tab label="Em atendimento" disabled={!isSessionCreatedFilterToday()}/>
                <Tab label="Finalizados" />
            </Tabs>
            <List style={{width: '100%'}}>
                {sessionCardsItems}
            </List>
        </Paper>
    );
}