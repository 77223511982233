
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { green, blue, red } from '@material-ui/core/colors';

import EventCard from "../event-card/EventCard";

const useStyles = makeStyles((theme) => ({
    panelTitle: {
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 'bold',
        color: blue[500],
        marginBottom:12,
        //padding: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    eventCard: {
        marginBottom:12,
        padding:12
    }
}));

export default function DoormopOpenEventsPanel() {
    const classes = useStyles();

    return (
        <div>
            <Typography
                className={classes.panelTitle}>
                Eventos aguardando atendimento
            </Typography>
            <EventCard></EventCard>
            <EventCard></EventCard>
        </div>
    );
}