
import React from "react";
import useWsServer from "../../../hooks/use-ws-server";
import {useListWithIdReducer, ListAction} from "../../../hooks/use-list-with-id-reducer";
import {fromJSON} from "../../../utils/json-utils";
import {isAppMocked} from "../../../utils/env-utils";
import {WatchedLocationsContext} from "../../../contexts/watched-locations-context";
import {SessionCardInfo} from "../../../models/session-card-info";
import {SessionStatus} from "../../../models/doormai/session-status";
import {SessionStatusEvent, DoormaiSessionEventType} from "../../../models/server-events/session-status-event";

export const WatchedSessionStatusContext = React.createContext(null);
WatchedSessionStatusContext.displayName = 'WatchedSessionStatusContext';

export const WatchedStatus = {
    IN_ATTENDANCE: 'in_attendance',
    ENDED: 'ended',
};

/**
 * This context keeps info about the which session status are actively being watched, 
 * and the last session info received from server.
 * The locations are read from @see WatchedLocationsContext
 *
 */
export const WatchedSessionStatusContextProvider = props => {
    const {watchedLocationsRef} = React.useContext(WatchedLocationsContext);
    //const watchedStatuses = React.useRef([]); //WatchedStatus
    const [inAttendanceSessions, setInAttendanceSessions] = useListWithIdReducer([]); //@see SessionCardInfo
    const [endedSessions, setEndedSessions] = useListWithIdReducer([]); //@see SessionCardInfo
    const [watchedErrorMsg, setWatchedErrorMsg] = React.useState('');

    const handleServerEventArrived = (serverEvent) => {
        if (serverEvent instanceof SessionStatusEvent) {
            const sessionId = serverEvent.cardInfo.sessionId;
            if ([DoormaiSessionEventType.SESSION_ENDED, DoormaiSessionEventType.SESSION_FEEDBACK].includes(serverEvent.type)) {
                const duplicatedEvent = endedSessions.find( cardInfo => cardInfo.sessionId === sessionId );
                //console.log('SessionEnded.duplicatedEvent', duplicatedEvent, sessionId, endedSessions);
                if (!duplicatedEvent)
                    setEndedSessions({item: serverEvent.cardInfo, type: ListAction.UNSHIFT});
                else
                    setEndedSessions({fieldId:'sessionId', item: serverEvent.cardInfo, type: ListAction.UPDATE});
                //delete from inAttendanceSessions
                setInAttendanceSessions({fieldId:'sessionId', item: serverEvent.cardInfo, type: ListAction.REMOVE});

            } else {
                const duplicatedEvent = inAttendanceSessions.find( cardInfo => cardInfo.sessionId === sessionId );
                //console.log('SessionStarted.duplicatedEvent', duplicatedEvent, sessionId, inAttendanceSessions);
                if (!duplicatedEvent)
                    setInAttendanceSessions({item: serverEvent.cardInfo, type: ListAction.UNSHIFT});
                else
                    setInAttendanceSessions({fieldId:'sessionId', item: serverEvent.cardInfo, type: ListAction.UPDATE});
            }
        }
    };
    const handleSocketConnectionChanged = (connectionState) => {
        console.log('sessionStatusContext.handleSocketConnectionChanged', connectionState);
        if (connectionState !== 'openedAndValidated') {
            // watchedStatuses.current = [];
        } //if the connection was opened that's nothing we can do here, because we don't know wich status must be watched

    };
    const {sendJson, websocketErrorMsg} = useWsServer(
        {onServerEventArrived: handleServerEventArrived, onSocketConnectionChanged: handleSocketConnectionChanged});

    React.useEffect(() => {
        console.log(`websocketErrorMsg = ${websocketErrorMsg} | errorMsg = ${watchedErrorMsg}`);
        if (websocketErrorMsg) {
            setWatchedErrorMsg(websocketErrorMsg);
        }
    }, [websocketErrorMsg]);

    const watchSessionStatus = async (watchedStatus, locationsFilter, sessionCreatedFilter, cardsLimit) => {
        let messageType = watchedStatus === WatchedStatus.ENDED ? 'WATCH_SESSIONS_ENDED' : 'WATCH_SESSIONS_IN_ATTENDANCE';
        // if (watchedStatuses.current.includes(watchedStatus)) {
        //     console.log('We are already watching this status', watchedStatus);
        //     return;
        // }
        //console.log('watchSessionStatus', messageType);

        let locationsIds;
        if (locationsFilter && locationsFilter.length>0) {
            locationsIds = locationsFilter.map( loc => loc._id);
        } else {
            const watchedLocations = watchedLocationsRef.current;
            if (!watchedLocations || watchedLocations.length === 0) {
                //console.log('Nenhuma localidade está sendo monitorada. Selecione as localidades!');
                setWatchedErrorMsg('Nenhuma localidade está sendo monitorada. Selecione as localidades!');
                return;
            }
            locationsIds = watchedLocations.map( location => location._id);
        }

        const requestJson = {
            messageType: messageType,
            locationsIds: locationsIds,
            sessionCreatedFilter: sessionCreatedFilter,
            limit: cardsLimit,
        };
        const handleResponse = (responseMessage) => {
            //console.log('WatchedSessionsStatus.handleResponse', responseMessage.data);
            if (responseMessage.success) {
                //watchedStatuses.current.push(watchedStatus);
                let cardInfoList = responseMessage.data.map( sessionCardJson => fromJSON(SessionCardInfo, sessionCardJson));
                if (watchedStatus === WatchedStatus.ENDED)
                    setEndedSessions({list: cardInfoList, type: ListAction.SET_LIST});
                else
                    setInAttendanceSessions({list: cardInfoList, type: ListAction.SET_LIST});
            } else {
                setWatchedErrorMsg(responseMessage.message);
            }
        };

        return await sendJson( requestJson, handleResponse);
    };

    const unwatchSessionStatus = async () => {
        let messageType = 'UNWATCH_SESSIONS_STATUS';
        // if (!watchedStatuses.current.includes(watchedStatus)) {
        //     console.log('We are NOT watching this status', watchedStatus);
        //     return;
        // }
        //console.log('unwatchSessionStatus', messageType);

        const requestJson = {
            messageType: messageType,
            locationsIds: watchedLocationsRef.current.map( location => location._id),
        };
        const handleResponse = (responseMessage) => {
            //console.log('UNwatchedSessionsStatus.handleResponse', responseMessage.data);
            if (responseMessage.success) {
                // watchedStatuses.current =
                //     watchedStatuses.current.filter( status => status !== watchedStatus);
            } else {
                setWatchedErrorMsg(responseMessage.message);
            }
        };

        return await sendJson( requestJson, handleResponse);
    }

    const contextValue = {
        watchedErrorMsg,
        inAttendanceSessions,
        endedSessions,
        watchSessionStatus,
        unwatchSessionStatus,
    }
    return (
        <WatchedSessionStatusContext.Provider value={contextValue}>{props.children}</WatchedSessionStatusContext.Provider>
    );

};


function getEndedSessionsMOCK() {
    return [
        {
            sessionId: (new Date()).getTime(),
            createdAt: new Date(),
            contactReasonDescription: 'Entrega para 301',
            sessionEndStatus: SessionStatus.ENDED_FULLY_UNDERSTOOD,
            "location": {
                "_id": "5f58e0b093d0811a3c68fde6",
                "name": "Condomínio Julio Rinaldi",
                "customerId": "Aster-SP"
            },
            callerTypeDescription: 'Entregador',
            callerName: 'iFood',
        }
    ]
}

function getInAttendanceSessionsMOCK() {
    return [
        {
            sessionId: (new Date()).getTime(),
            createdAt: new Date(),
            contactReasonDescription: 'Entrega para 101',
            sessionEndStatus: SessionStatus.NOT_ENDED,
            "location": {
                "_id": "5f58e0b093d0811a3c68fde6",
                "name": "Condomínio Julio Rinaldi",
                "customerId": "Aster-SP"
            },
            callerTypeDescription: 'Entregador',
            callerName: 'Mercado Livre',
        }
    ]
}

