
export const HttpStatusCode = {
    SUCCESS: 200
};

export class HttpResponse {
    constructor(msg, data) {
        this.statusCode = null;
        this.success = false;
        this.message = msg;
        this.data = data;
    }

    toJSON() {
        return {
            //type: this.constructor.name,  This in production will not work because minified code...
            statusCode: this.statusCode,
            success: this.success,
            message: this.message,
            data: this.data,
        };
    }

    toString() {
        return JSON.stringify(this.toJSON());
    }

    fromJSON(infoJSON) {
        if (infoJSON) {
            for (let key in infoJSON) {
                this[key] = infoJSON[key];
            }
        }
    }

    // static fromJSON(objectClassname, infoJSON) {
    //     if (infoJSON) {
    //         let result = eval(`new ${objectClassname}()`);
    //         result.fromJSON(infoJSON);
    //         return result;
    //     } else
    //         return null;
    // }
    static fromJSON(ObjectClass, infoJSON) {
        if (infoJSON) {
            let result = new ObjectClass();
            result.fromJSON(infoJSON);
            return result;
        } else
            return null;
    }

}

