import {copyFromJSON, fromJSON} from "../../utils/json-utils";
import {SessionKPIInfo} from "../session-kpi-info";


export class SessionKPIEvent {
    constructor() {
        this.locationId = null;
        this.kpiType = null; //@see KPIType at SessionKpiInfo
        this.kpiInfo = null; //SessionKpiInfo
        this.eventUserId = null;
    }

    fromJSON(infoJSON) {
        copyFromJSON(this, infoJSON);
        this.kpiInfo = fromJSON(SessionKPIInfo, infoJSON.kpiInfo);
    }

}