const {HttpResponse} = require('./http-response')

export class NetworkErrorResponse extends HttpResponse {
    constructor(msg, data) {
        super(msg || 'Servidor indisponível neste momento! Tente novamente ou aguarde um pouco...', data);
        this.statusCode = NetworkErrorResponse.httpStatus;
        this.success = false;
    }

    static get httpStatus() {
        return 503;
    }


}
