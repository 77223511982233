
import {fromJSON} from '../utils/json-utils';
import {SessionFeedback} from './session-feedback';

export class SessionCardInfo {
    constructor(sessionId) {
        this.sessionId = sessionId;
        this.createdAt = null;
        this.location = null;
        this.contactReasonDescription = ''; //“Entrega para 301 | Visita para 202 | ...”,
        this.dwellerCommandDescription = '';
        this.lastUpdatedAt = null;
        this.sessionEndStatus = ''; //@see SessionStatus
        this.forwardReasonDescription = '';
        this.callerTypeDescription = '';
        this.callerName = '';
        this.sessionResult = ''; //one of SessionResultMetric
        this.audiosChannels = {}; // Key = PartcipantType | Value = String[] URLs to recorded audio from channel on Voximplant
        this.audioObjects = {}; // //Key = channel.id  |  Value = String[] -> URLs to recorded audio from channel on S3
        this.voximplantInfo = {
            logUrl: ''
        };
        this.feedbacks = []; //SessionFeedback[]
    }

    fromJSON(infoJSON) {
        if (infoJSON) {
            for (let key in infoJSON) {
                if (key !== 'feedbacks')
                    this[key] = infoJSON[key];
            }
            if (infoJSON.feedbacks) {
                infoJSON.feedbacks.forEach( feedback =>
                    this.feedbacks.push( fromJSON(SessionFeedback, feedback) ));
            }
        }
    }

    toJSON() {
        return Object.assign({}, this);
    }

    static fromJSON(infoJSON) {
        if (infoJSON) {
            let result = new SessionCardInfo();
            result.fromJSON(infoJSON);
            return result;
        } else
            return null;
    }
}
