
import { USER_ADMIN } from '../consts'
import {post, getDataFromResponse} from "../utils/server-utils";
import {isAppMocked} from "../utils/env-utils";
import {InvalidLoginResponse} from "../models/http-responses/invalid-login-response";

const useAuth = () =>{

    async function authenticate(user, password){
        if (isAppMocked()) {
            return await authenticateMOCK(user, password);
        } else {
            const response = await post('/auth/login', {
                login: user,
                password: password,
            });
            //console.log('auth.response', response);
            if (response instanceof InvalidLoginResponse)
                throw new Error('Senha ou Usuário invalidos');
            else
                return getDataFromResponse(response);
        }
    }

    return{authenticate}
}

function authenticateMOCK(user, password) {
    return new Promise( (accept, refuse) => {
        setTimeout(() => {
            if (user === USER_ADMIN.usuario && password === USER_ADMIN.senha)
                accept( {token: USER_ADMIN.token, name: USER_ADMIN.name} );
            else
                refuse(new Error('Senha ou Usuário invalidos'));
        }, 1000);
    })  ;
}


export default useAuth