const {HttpResponse} = require('./http-response')

export class EntityNotFoundResponse extends HttpResponse {
    constructor(msg, data) {
        super(msg, data);
        this.statusCode = EntityNotFoundResponse.httpStatus;
        this.success = false;
    }

    static get httpStatus() {
        return 404;
    }

}
