const {HttpResponse, HttpStatusCode} = require('./http-response')

export class SuccessResponse extends HttpResponse {
    constructor(msg, data) {
        super(msg, data);
        this.statusCode = SuccessResponse.httpStatus;
        this.success = true;
    }

    static get httpStatus() {
        return HttpStatusCode.SUCCESS;
    }
}
