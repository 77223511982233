const {NotificationAction} = require("./notification-constants");

export class BasicEventNotification {
    constructor(notificationType, sessionEvent) {
        this.type = notificationType;
        this.location = sessionEvent?.location;
        this.isAboutEvent = {
            eventClassName: sessionEvent?.eventClassName,
            eventDescription: sessionEvent?.eventDescription,
            type: sessionEvent?.type, //depends on eventClassName
            status: sessionEvent?.status,
            _id: sessionEvent?._id,
        };
        this.possibleActions = [NotificationAction.PICKUP, NotificationAction.IGNORE]; //NotificationAction
    }

    get eventId() {
        return this.isAboutEvent ? this.isAboutEvent._id : null;
    }
    toString() {
        return JSON.stringify(this.toJSON());
    }

    fromJSON(infoJSON) {
        if (infoJSON) {
            for (let key in infoJSON) {
                this[key] = infoJSON[key];
            }
        }
    }

    toJSON() {
        return Object.assign({}, this);
    }

    static fromJSON(ObjectClass, infoJSON) {
        if (infoJSON) {
            let result = new ObjectClass();
            result.fromJSON(infoJSON);
            return result;
        } else
            return null;
    }
}

