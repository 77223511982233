
export const NotificationType = {
    NEW_EVENT_NOTIFICATION: 'NEW_EVENT_NOTIFICATION',
    EVENT_UPDATED_NOTIFICATION: 'EVENT_UPDATED_NOTIFICATION',
};

export const NotificationAction = {
    PICKUP: 'pickup',
    IGNORE: 'ignore',
};


