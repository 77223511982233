import React from 'react'
import { FiSettings } from 'react-icons/fi';
import { useHistory } from "react-router-dom";
import { withStyles,makeStyles } from "@material-ui/core/styles";
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';

import './HeaderComponent.css';
import useUserToken from "../../hooks/use-user-token";
import useWsServer from "../../hooks/use-ws-server";
import {UserContext} from "../../contexts/user-context";
import {WatchedLocationsContext} from "../../contexts/watched-locations-context";
import {DOORMOP_VERSION} from "../../consts";

const HeaderTitleTypography = withStyles({
    root: {
        color: "#5589FF",
        fontSize: "18px",
        fontWeight: 'bold',
    }
})(Typography);

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

const UNAVAILABLE_MSG = 'Fique disponível para receber os eventos dos condomínios!';

function Header() {
    const classes = useStyles();
    const {getFirstName, invalidateToken} = useUserToken();
    const {userAvailable, setUserAvailable} = React.useContext(UserContext);
    const {watchedLocationsRef, watchLocations,
        unwatchLocations, watchedLocationsErrorMsg} = React.useContext(WatchedLocationsContext);
    const history = useHistory();
    const lastWatchedLocations = React.useRef([]);
    const [headerErrorMsg, setHeaderErrorMsg] = React.useState('');
    const [headerWarningMsg, setHeaderWarningMsg] = React.useState('');

    React.useEffect( () => {
        setHeaderErrorMsg(watchedLocationsErrorMsg);
    }, [watchedLocationsErrorMsg]);

    React.useEffect( () => {
        if (userAvailable) {
            if (headerWarningMsg === UNAVAILABLE_MSG)
                setHeaderWarningMsg('');
        }
    }, [userAvailable]);

    const handleSocketConnectionChanged = (connectionState) => {
        console.log('handleSocketConnectionChanged', connectionState);
        if (connectionState === 'openedAndValidated') {
            if (headerErrorMsg === 'Conexão com servidor foi fechada! Tentando reconectar...')
                setHeaderErrorMsg('');
        } else {
            setHeaderErrorMsg('Conexão com servidor foi fechada! Tentando reconectar...');
        }
    };
    useWsServer({onSocketConnectionChanged: handleSocketConnectionChanged});

    const handleUserAvailableChange = (e) => {
        setUserAvailable(e.target.checked);
        console.log('lastWatchedLocations.current', lastWatchedLocations.current, watchedLocationsRef.current);
        if (!e.target.checked) {
            console.log('User is not available anymore');
            lastWatchedLocations.current = [...watchedLocationsRef.current];
            unwatchLocations(watchedLocationsRef.current);
            setHeaderWarningMsg(UNAVAILABLE_MSG);
        } else {
            console.log('User is available again');
            if (lastWatchedLocations.current.length === 0) {
                history.push({
                    pathname: '/welcome',
                });
            } else {
                //start to receive server notifications about the locations
                watchLocations(lastWatchedLocations.current);
            }
        }
    };

    const handleUserMenuClick = () => {
        invalidateToken();
        window.location.href = '/';
        // history.go(0);
        // history.push({
        //     pathname: '/',
        // });
    };

    return (
        <AppBar position="relative" style={{
            backgroundColor: 'white',
        }}>
            <Toolbar>
                <Link href='/' style={{ textDecoration: 'none' }}>
                    <HeaderTitleTypography variant="h6" noWrap component="span">
                        Doormop
                    </HeaderTitleTypography>
                     <span> - Monitoramento em tempo real do Doormai - {DOORMOP_VERSION}</span>
                </Link>
                <div className={classes.grow} />
                <IconButton className={classes.menuButton} aria-label="menu">
                    <FiSettings/>
                </IconButton>
                <label className="switch">
                    <input type="checkbox" id="togBtn" onChange={handleUserAvailableChange} checked={userAvailable}/>
                    <div className="slider round">
                        <span className="on">Disponível</span>
                        <span className="off">Indisponível</span>
                    </div>
                </label>
                <Button onClick={handleUserMenuClick}>{getFirstName()}</Button>
            </Toolbar>
            {headerErrorMsg && <Alert severity="error">{headerErrorMsg}</Alert>}
            {headerWarningMsg && <Alert severity="warning">{headerWarningMsg}</Alert>}
        </AppBar>
    );
}

export default Header;
