
import React from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import { grey } from '@material-ui/core/colors';
import {makeStyles} from "@material-ui/core/styles/index";
import Alert from '@material-ui/lab/Alert';

import useSessionFeedbacks from '../../hooks/use-session-feedbacks';
import {SessionFeedback} from "../../models/session-feedback";
import {fromJSON} from "../../utils/json-utils";

export const CardInfoMode = {
    READ_ONLY: 'readOnly',
    INSERT: "insert",
    UPDATE: "update",
    DELETE: "delete",
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0.8,
    },
    listItem: {
        padding: theme.spacing(-2),
        margin: theme.spacing(-2),
    },
    primaryListItem: {
        fontSize: theme.typography.pxToRem(13),
        color: grey[600],
    },
}));

export default function CardInfoFeedbacks(props) {
    const classes = useStyles();
    const {cardInfo, mode, onSaveCancel} = props;
    const commentRef = React.useRef('');
    const [internalMode, setInternalMode] = React.useState(CardInfoMode.READ_ONLY);
    const [selectedFeedback, setSelectedFeedback] = React.useState(null);
    const [errorMsg, setErrorMsg] = React.useState('');

    React.useEffect( () => {
        setInternalMode(mode);
    }, [mode]);

    const onEditEnded = () => {
        setSelectedFeedback(null);
        onSaveCancel(cardInfo.sessionId);
        setInternalMode(CardInfoMode.READ_ONLY);
    };

    const handleSaveFeedbackError = (e) => {
        console.log('handleSaveFeedbackError', e);
        setErrorMsg(e.message);
    }
    const {saveSessionFeedback, deleteSessionFeedback, updateSessionFeedback} =
        useSessionFeedbacks({errorCallback:handleSaveFeedbackError});

    const handleSaveFeedback = (event) => {
        event.preventDefault();
        event.stopPropagation();
        //console.log('handleSaveFeedback', internalMode);
        if (internalMode === CardInfoMode.INSERT) {
            const newFeedback = new SessionFeedback();
            newFeedback.sessionId = cardInfo.sessionId;
            newFeedback.createdAt = new Date();
            newFeedback.comment = commentRef.current;
            saveSessionFeedback(newFeedback).then((serverFeedback) => {
                if (serverFeedback) {
                    cardInfo.feedbacks.push( fromJSON(SessionFeedback, serverFeedback) );
                    onEditEnded();
                } else {
                    handleSaveFeedbackError(new Error('Feedback não foi salvo no servidor!'))
                }
            });
        } else {
            selectedFeedback.createdAt = new Date();
            //console.log('commentRef.current', commentRef.current);
            selectedFeedback.comment = commentRef.current;
            updateSessionFeedback(selectedFeedback).then((serverFeedback) => {
                if (serverFeedback) {
                    onEditEnded();
                } else {
                    handleSaveFeedbackError(new Error('Feedback não foi atualizado no servidor!'))
                }
            });
        }
    };

    const handleDeleteFeedback = (event) => {
        event.preventDefault();
        event.stopPropagation();
        //console.log('handleDeleteFeedback');
        deleteSessionFeedback(selectedFeedback).then((result) => {
            if (result) {
                cardInfo.feedbacks = cardInfo.feedbacks.filter(feedback => feedback._id !== selectedFeedback._id );
                onEditEnded();
            } else {
                handleSaveFeedbackError(new Error('Feedback não foi excluído no servidor!'))
            }
        });
    };

    const handleCancel = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onEditEnded();
    };

    const handleInputClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleInputChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        commentRef.current = event.target.value;
    };

    const handleAlertClosed = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setErrorMsg('');
    };

    const handleFeedbackClick = (event, feedbackIndex) => {
        event.preventDefault();
        event.stopPropagation();
        //console.log("handleFeedbackClick", feedbackIndex, cardInfo.feedbacks[feedbackIndex]);
        setSelectedFeedback( cardInfo.feedbacks[feedbackIndex] );
        setInternalMode(CardInfoMode.UPDATE);
    };

    const feedbacksItems = [];
    let feedback;
    //console.log("internalMode", internalMode, mode);
    for (let a=0; a<cardInfo.feedbacks.length; a++) {
        feedback = cardInfo.feedbacks[a];
        feedbacksItems.push( (
            <div key={feedback._id} className={classes.listItem}
                onClick={(e) => handleFeedbackClick(e, a)}>
                <ListItem key={feedback._id} alignItems="flex-start" role={undefined} >
                    <ListItemText
                        primary={
                            <div>
                                <Typography
                                    component="span"
                                    className={classes.primaryListItem}
                                >
                                    {`[${feedback.userInfo.fullName}]  ${feedback.comment}`}
                                </Typography>
                            </div>
                        }
                    />
                </ListItem>
            </div>
        ));
    }

    return (
        <div>
            <List style={{width: '100%'}}>
                {feedbacksItems}
            </List>
            {errorMsg && <Alert onClose={handleAlertClosed} severity="error">{errorMsg}</Alert>}
            {[CardInfoMode.INSERT, CardInfoMode.UPDATE].includes(internalMode)
                &&
                <Input id="ed-comment" variant="filled" multiline fullWidth  autoFocus margin="none" p={0}
                       placeholder="Escreva seu comentário..."
                       style={{background: grey[200]}}
                       onClick={handleInputClick}
                       onKeyPress={(event) => {
                           if (event.key === 'Enter')
                               handleSaveFeedback(event)
                       }}
                    defaultValue={selectedFeedback ? selectedFeedback.comment : ''}
                    onChange={handleInputChange}
                />
            }
            {[CardInfoMode.INSERT, CardInfoMode.UPDATE].includes(internalMode)
                &&
                <ButtonGroup variant="text" size="small" color="primary" style={{float: "right"}}>
                    <Button onClick={handleSaveFeedback}>Salvar</Button>
                    {internalMode === CardInfoMode.UPDATE
                        && <Button onClick={handleDeleteFeedback}>Excluir</Button>}
                    <Button onClick={handleCancel}>Cancelar</Button>
                </ButtonGroup>
            }
        </div>
    );
}