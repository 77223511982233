
const {NotificationType} = require('./notification-constants');
const {BasicEventNotification} = require('./basic-event-notification');

export class EventUpdatedNotification extends BasicEventNotification {
    constructor(sessionEvent) {
        super(NotificationType.EVENT_UPDATED_NOTIFICATION, sessionEvent);
    }

    static fromJSON(json) {
        return BasicEventNotification.fromJSON(EventUpdatedNotification, json);
    }
}
