export const USER_ADMIN = {
    usuario:"admin",
    senha:"12345678",
    token: '1234',
    name: 'Rodrigo Dias de Azevedo',
};

const DOORMAI_WHATSAPP_NUMBER = '554898226804';

export const ExternalLinks = {
    FORGOT_PASSWORD: `https://api.whatsapp.com/send/?phone=${DOORMAI_WHATSAPP_NUMBER}&text=esqueci_minha_senha`,
    OPEN_SUPPORT_TICKET: `https://api.whatsapp.com/send/?phone=${DOORMAI_WHATSAPP_NUMBER}&text=abrir_ticket_suporte`,
};

export const DOORMOP_VERSION = process.env.REACT_APP_DOORMOP_VERSION;