
import {SessionCardInfo} from '../session-card-info';
import {copyFromJSON, fromJSON} from '../../utils/json-utils';

//@see on Doormop Server - Doormai Utils
export const DoormaiSessionEventType = {
    SESSION_ENDED: 'SESSION_ENDED',
    SESSION_IN_ATTENDANCE: 'SESSION_IN_ATTENDANCE',
    SESSION_FEEDBACK: 'SESSION_FEEDBACK',
};

export class SessionStatusEvent {
    constructor() {
        this.type = null; //DoormaiSessionEventType
        this.cardInfo = new SessionCardInfo();
    }

    fromJSON(infoJSON) {
        copyFromJSON(this, infoJSON);
        this.cardInfo = fromJSON(SessionCardInfo, infoJSON.cardInfo);
    }
}