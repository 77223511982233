
import {SessionEvent} from "./session-event";

export class ParamDetectedEvent extends SessionEvent {
    constructor() {
        super('ParamDetectedEvent');
        this.type = null;
        this.detectedIntentName = null;
        this.invalidParams = {}; //key: paramName  value: {text: string, audioUrl: string, options:{}}
        this.validParams = {};
        this.paramsIdentified = {}; ////key: paramName  value: {notUnderstood: booleam, otherOption: OtherOption, value:string}
        this.attendedBy = null; //user info of who is resolving the event
        this.attendanceStartedAt = null;
    }

}