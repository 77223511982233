import React from 'react'
import { useHistory } from "react-router-dom";
import { FiAlertTriangle } from 'react-icons/fi';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {useFormik} from "formik/dist/index";
import Alert from '@material-ui/lab/Alert';

import './WelcomePage.css';

import useUserToken from "../../hooks/use-user-token";
import useUserPreferences from "../../hooks/use-user-preferences";
import useUserLocations from "../../hooks/use-user-locations";
import {WatchedLocationsContext} from "../../contexts/watched-locations-context";
import {UserContext} from "../../contexts/user-context";

const useStyles = makeStyles((theme) => ({
    boxLocations: {
        backgroundColor: 'white',
        borderRadius: "6px",

},
}));

function WelcomePage() {
    const classes = useStyles();
    const history = useHistory();
    const {getFirstName} = useUserToken();
    const [listedLocations, setListedLocations] = React.useState([]); //Location[]
    const {watchLocations} = React.useContext(WatchedLocationsContext);
    const {setUserAvailable, setLastWatchedLocations} = React.useContext(UserContext);
    const [formErrorMsg, setFormErrorMsg] = React.useState('');
    const [showAllLocationsBtn, setShowAllLocationsBtn] = React.useState(false);

    const handleGetUserPrefError = (e) => {
        console.log('handleGetUserPrefError', e);
        //TODO show e.message error on error bar
    };
    const {userPreferences} = useUserPreferences({errorCallback:handleGetUserPrefError});
    const {userLocations} = useUserLocations({errorCallback:handleGetUserPrefError});

    React.useEffect(()=>{
        console.log('useEffect.userPreferences', userPreferences);
        if (userPreferences) {
            if (userPreferences.lastWatchedLocations.length>0) {
                setListedLocations(userPreferences.lastWatchedLocations);
                formik.values.locationsIdsChecked =
                    userPreferences.lastWatchedLocations.map( location => location._id);
                setShowAllLocationsBtn(true);
            } else {
                setShowAllLocationsBtn(false);
            }
        }
    },[userPreferences]);

    React.useEffect(()=>{
        if (!showAllLocationsBtn && userLocations) {
            setListedLocations(userLocations);
        }
    },[userLocations]);

    const handleValidation = values => {
        const errors = {};
        console.log(`validateForm = ${values.locationsIdsChecked.length}`);
        if (values.locationsIdsChecked.length === 0) {
            errors.locationsIdsChecked = `Você precisa selecionar pelo menos 1 localidade!`;
            setFormErrorMsg(`Você precisa selecionar pelo menos 1 localidade!`);
        }
        return errors;
    };

    const handleSubmit = values => {
        console.log(JSON.stringify(values, null, 2));
        try {
            const newWatchedLocations =
                values.locationsIdsChecked.map( lid => listedLocations.find(location => location._id === lid) );
            watchLocations(newWatchedLocations);
            setLastWatchedLocations(newWatchedLocations);
            setUserAvailable(true);
            history.push({
                pathname: '/main',
            });
        } catch (e) {
            console.log('setUserWatchedLocations.error', e);
            setFormErrorMsg(e.message);
        }
    };

    const handleSeeAllLocations = () => {
        setListedLocations(userLocations);
        setShowAllLocationsBtn(false);
    };

    const handleSelectAll = () => {
        formik.values.locationsIdsChecked =
            listedLocations.map( location => location._id);

    };
    const handleClearSelection = () => {
        formik.values.locationsIdsChecked = [];
    };

    const formik = useFormik({
        initialValues: {locationsIdsChecked:[]},
        validate: handleValidation,
        onSubmit: handleSubmit,
    });

    const rowsPerColumn = Math.ceil( listedLocations.length/2 );
    let locationsFirstColumn = [], locationsSecondColumn=[];
    for (let a=0; a<rowsPerColumn; a++) {
        let listedLocation = listedLocations[a];
        locationsFirstColumn.push(
            (
                <FormControlLabel key={listedLocation._id}
                                  control={<Checkbox checked={formik.values.locationsIdsChecked.indexOf(listedLocation._id)>=0}
                                                     onChange={formik.handleChange} name="locationsIdsChecked"
                                                     value={listedLocation._id} />}
                                  label={listedLocation.name}
                />
            )
        );
    }
    for (let a=rowsPerColumn; a<listedLocations.length; a++) {
        let listedLocation = listedLocations[a];
        locationsSecondColumn.push(
            (
                <FormControlLabel key={listedLocation._id}
                                  control={<Checkbox checked={formik.values.locationsIdsChecked.indexOf(listedLocation._id)>=0}
                                                     onChange={formik.handleChange} name="locationsIdsChecked" value={listedLocation._id} />}
                                  label={listedLocation.name}
                />
            )
        );
    }
    return (
        <Box display="flex" flexDirection="column" p={1} m={1}>
            <Box display="flex" flexDirection="row" className="aviso" m={1} >
                <FiAlertTriangle className="icon-aviso text-white"/>
                <div className="d-flex flex-column w-20">
                    <p className="text-white title-aviso font-weight-bold mt-2">Aviso importante</p>
                    <p className="subtitle text-white font-weight-light line-25 w-75">Mantenha este app aberto para receber os eventos dos condomínios.</p>
                </div>
            </Box>
            <Box display="flex" flexDirection="column" className={classes.boxLocations} m={1}>
                <Box display="flex" flexDirection="column" className="px-4 py-3 div-select-ola" p={1} pl={3}>
                    <p className="subtitle texto-cinza" >Olá <b>{getFirstName()}</b> ! <br/>
                    Aqui você escolhe quais localidades vai monitorar! Escolha <b>no mínimo 1 localidade</b>, ok?</p>
                </Box>
                <Box display="flex" flexDirection="column" className="px-4 py-3 div-select-ola" p={1} pl={3}>
                    <p className="subtitle font-weight-bold mt-3 texto-cinza" >
                        {showAllLocationsBtn && "Estas foram as últimas localidades escolhidas por você..."}
                        {!showAllLocationsBtn && "Todas as localidades disponíveis..."}
                    </p>
                    {formErrorMsg && <Alert severity="error">{formErrorMsg}</Alert>}
                    <form name="form"
                          onSubmit={formik.handleSubmit} onChange={()=>setFormErrorMsg('')}>
                        <FormControl component="fieldset" className={classes.formControl} fullWidth>
                            <FormGroup>
                                <Grid container spacing={2} >
                                    <Grid container item xs={6} direction="column" >
                                        {locationsFirstColumn}
                                    </Grid>
                                    <Grid container item xs={6} direction="column" >
                                        {locationsSecondColumn}
                                    </Grid>
                                </Grid>
                            </FormGroup>

                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="d-flex mx-3">
                                {showAllLocationsBtn &&
                                    <a onClick={handleSeeAllLocations} href="#" className="texto-cinza" style={{margin:8}}>Mostrar todas</a>}
                                    <a onClick={handleSelectAll} href="#" className="texto-cinza" style={{margin:8}}>Selecionar todas</a>
                                    <a onClick={handleClearSelection} href="#" className="texto-cinza" style={{margin:8}}>Limpar seleção</a>
                                </div>
                                <div style={{flexGrow:1}} />
                                <Button color="primary" variant="contained" type="submit" startIcon={<PlayCircleOutlineIcon/>}>
                                    Começar a atender
                                </Button>
                            </Box>
                        </FormControl>
                    </form>
                </Box>
            </Box>
        </Box>
    );
}

export default WelcomePage;
