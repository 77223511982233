const {HttpResponse} = require('./http-response');

export class NotAuthorizedResponse extends HttpResponse {
    constructor(msg) {
        super(msg || `NOT AUTHORIZED`);
        this.statusCode = NotAuthorizedResponse.httpStatus;
        this.success = false;
    }

    static get httpStatus() {
        return 401;
    }

}
