
import {BadRequestResponse} from '../models/http-responses/bad-request-response';
import {EntityAlreadyExistsResponse} from '../models/http-responses/entity-already-exists-response';
import {EntityNotFoundResponse} from '../models/http-responses/entity-not-found-response';
import {InternalErrorResponse} from '../models/http-responses/internal-error-response';
import {NotAuthorizedResponse} from '../models/http-responses/not_authorized-response';
import {SuccessResponse} from '../models/http-responses/success-response';
import {ValidLoginResponse} from '../models/http-responses/valid-login-response';
import {ValidationErrorResponse} from '../models/http-responses/validation-error-response';
import {InvalidLoginResponse} from '../models/http-responses/invalid-login-response';
import {HttpResponse} from '../models/http-responses/http-response';

export function buildResponseFromJSON( resultJson ) {
    if (resultJson && resultJson.type) {
        try {
            let ResponseClass;
            switch (resultJson.type) {
                case 'BadRequestResponse':
                    ResponseClass = BadRequestResponse; break;
                case 'EntityAlreadyExistsResponse':
                    ResponseClass = EntityAlreadyExistsResponse; break;
                case 'EntityNotFoundResponse':
                    ResponseClass = EntityNotFoundResponse; break;
                case 'InternalErrorResponse':
                    ResponseClass = InternalErrorResponse; break;
                case 'InvalidLoginResponse':
                    ResponseClass = InvalidLoginResponse; break;
                case 'NotAuthorizedResponse':
                    ResponseClass = NotAuthorizedResponse; break;
                case 'SuccessResponse':
                    ResponseClass = SuccessResponse; break;
                case 'ValidLoginResponse':
                    ResponseClass = ValidLoginResponse; break;
                case 'ValidationErrorResponse':
                    ResponseClass = ValidationErrorResponse; break;
                default:
                    throw new Error(`Unknown response type: ${resultJson.type}`);
            };
            return HttpResponse.fromJSON(ResponseClass, resultJson);

        } catch (e) {
            //console.log('buildResponseFromJSON.error', e);
            throw new Error(`Invalid HttpResponse JSON: ${JSON.stringify(resultJson, null, 2)}`);
        }
    } else {
        throw new Error(`Response type undefined: ${JSON.stringify(resultJson, null, 2)}`);
    }
}

export function buildResponseFromStatusCode( status ) {
    switch (status) {
        case BadRequestResponse.httpStatus:
            return new BadRequestResponse();
        case EntityAlreadyExistsResponse.httpStatus:
            return new EntityAlreadyExistsResponse();
        case EntityNotFoundResponse.httpStatus:
            return new EntityNotFoundResponse();
        case InternalErrorResponse.httpStatus:
            return new InternalErrorResponse();
        case NotAuthorizedResponse.httpStatus:
            return new NotAuthorizedResponse();
        case SuccessResponse.httpStatus:
            return new SuccessResponse();
        case ValidLoginResponse.httpStatus:
            return new ValidLoginResponse();
        case ValidationErrorResponse.httpStatus:
            return new ValidationErrorResponse();
        default:
            throw new Error(`Unknown http status code: ${status}`);
    }
}
