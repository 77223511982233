
import {useState} from 'react';
import { useFormik } from 'formik'; //https://formik.org/docs/overview
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
// import PropTypes from 'prop-types';

import './LoginPage.css';
import back from '../../assets/bg-predio.jpg'
import useAuth from '../../hooks/useAuth'
import useUserToken from "../../hooks/use-user-token";
import {ExternalLinks, USER_ADMIN} from "../../consts";
import {isProduction} from "../../utils/env-utils";

/**
 * @see https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
 */

export default function LoginPage() {
    const [loginErrorMsg, setLoginErrorMsg] = useState('');
    const {setUserToken} = useUserToken();
    const {authenticate} = useAuth();

    const initialValues = {
        username: '',
        password: '',
    };

    if (!isProduction()) {
        initialValues.username = USER_ADMIN.usuario;
        initialValues.password = USER_ADMIN.senha;

    }

    const handleValidate = values => {
        const errors = {};
        if (!values.username) {
            errors.username = 'Campo obrigatório';
        }
        if (!values.password) {
            errors.password = 'Campo obrigatório';
        }
        return errors;
    };

    const handleSubmit = async values => {
        console.log(JSON.stringify(values, null, 2));
        try {
            const userToken =
                await authenticate(values.username, values.password);
            setUserToken(userToken);
            window.location.reload();
        } catch (e) {
            console.log('LoginPage.submit.error', e);
            setLoginErrorMsg(e.message);
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        validate: handleValidate,
        onSubmit: handleSubmit,
    });

    return (
        <Box display="flex" flexDirection="row-reverse"
             style={{
                 backgroundImage: `url(${back})`,
                 backgroundPosition: "center",
                 backgroundRepeat: "no-repeat",
                 backgroundSize: "cover",
                 width: "100%",
                 height: "100vh"}}>
            <Box width={350} align="center" flexDirection="column" alignSelf="center" p={3}
                 bgcolor="background.paper"
                 height="90vh">
                <Box height={0.1}> </Box>
                <Box marginBottom={4}>
                    <h2 className="font-weight-bold title" style={{marginBottom:0}}>Doormop</h2>
                    <p className="texto" style={{"margin":0}}>Monitoramento em tempo real do Doormai</p>
                </Box>
                <Box align="left">
                    <p className="subtitle" >Bem vindo(a) 👋🏻</p>
                    <p className="texto" >Por favor, entre com suas credenciais abaixo para acessar a plataforma.</p>
                </Box>
                <Box align="left">
                    {loginErrorMsg && <Alert severity="error">{loginErrorMsg}</Alert>}
                    <form onSubmit={formik.handleSubmit} onChange={()=>setLoginErrorMsg('')}>
                        <label htmlFor="username">Usuário</label>
                        <TextField
                            fullWidth
                            id="username"
                            name="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            helperText={formik.touched.username && formik.errors.username}
                            variant="outlined"
                            margin="dense"
                        />
                        <label htmlFor="password">Senha</label>
                        <TextField
                            fullWidth
                            id="password"
                            name="password"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            variant="outlined"
                            margin="dense"
                        />
                        <p/>
                        <Button color="primary" variant="contained" fullWidth type="submit">
                            Acessar
                        </Button>
                    </form>
                </Box>
                <Box marginTop={2}>
                    <a href={ExternalLinks.FORGOT_PASSWORD} target="_blank" rel="noreferrer" className="texto" >Esqueci minha senha</a>
                </Box>
                <Box marginTop={8}>
                    <p className="texto" style={{marginBottom:0}}>Está enfrentando algum problema?</p>
                    <a href={ExternalLinks.OPEN_SUPPORT_TICKET} target="_blank" rel="noreferrer" className="texto line-0">Fale com nosso suporte.</a>
                </Box>
            </Box>
        </Box>
    );
};