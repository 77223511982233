
import React from "react";
import useWsServer from "../hooks/use-ws-server";

export const UserContext = React.createContext(null);
UserContext.displayName = 'UserContext';
export const UserContextProvider = props => {

    const {sendJson} = useWsServer();

    props.value.setLastWatchedLocations = async (newWatchedLocations) => {
        const requestJson = {
            messageType: 'SET_USER_LAST_WATCHED_LOCATIONS',
            locationsIds: newWatchedLocations.map( loc => loc._id ),
        };
        const handleResponse = (responseMessage) => {
            //console.log('setLastWatchedLocations.handleResponse', responseMessage.data);
            if (!responseMessage.success) {
                //setWatchedErrorMsg(responseMessage.message);
            }
        };

        return await sendJson( requestJson, handleResponse);

    };

    return (
        <UserContext.Provider value={props.value}>{props.children}</UserContext.Provider>
    );

};