const {HttpResponse} = require('./http-response')

export class ValidationErrorResponse extends HttpResponse {
    constructor(msg, data) {
        super(msg, data);
        this.statusCode = ValidationErrorResponse.httpStatus;
        this.success = false;
    }

    static get httpStatus() {
        return 422;
    }
}
