import {useReducer} from "react";

export const ListAction = {
    ADD: 'add',
    REMOVE: 'remove',
    SET_LIST: 'setList',
    UPDATE: 'update',
    UNSHIFT: 'unshift', //add to the beginning of the list
};

function listWithIdReducer(state, action) {
    let itemIndex, clone;
    const idFieldName = action.fieldId || '_id' || 'id';
    switch(action.type) {
        case ListAction.ADD:
            return [...state, action.item];
        case ListAction.UNSHIFT:
            return [action.item, ...state];
        case ListAction.REMOVE:
            itemIndex = state.findIndex(item => item[idFieldName] === action.item[idFieldName]);
            if(itemIndex < 0) {
                return state;
            }
            clone = [...state];
            clone.splice(itemIndex, 1);
            return clone;
        case ListAction.UPDATE:
            itemIndex = state.findIndex(item => {
                return item[idFieldName] === action.item[idFieldName]
            });
            if(itemIndex < 0) {
                return state;
            }
            clone = [...state];
            clone[itemIndex] = action.item;
            return clone;
        case ListAction.SET_LIST:
            return action.list;
        default:
            return state;
    }
}

export function useListWithIdReducer(initialList) {
    return useReducer(listWithIdReducer, initialList);
}