
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { FaRobot } from 'react-icons/fa'
import { green, blue, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    participantType: {
        fontSize: theme.typography.pxToRem(14),
        marginLeft:4,
        marginRight:4,
        marginBottom:0,
    },
    condoName: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'bold',
        marginBottom:0,
        // color: blue[500],
        // marginBottom:12,
        //padding: 12,
    },
    eventDescription: {
        fontSize: theme.typography.pxToRem(18),
        // fontWeight: 'bold',
        color: green[400],
    },
    answerButton: {
        margin: 4,
        backgroundColor: green[400],
        color: '#ffffff',
    },
    cancelButton: {
        margin: 4,
        backgroundColor: red[200],
        color: '#ffffff',
    },
    eventCard: {
        marginBottom:12,
        padding:12,
        borderColor: green[300],
        borderWidth: 1,
    },
    participantAvatar: {
        color: green[400],
        backgroundColor: green[100],
        margin: 4,
    },

    // paper: {
    //     padding: theme.spacing(2),
    //     textAlign: 'center',
    //     color: theme.palette.text.secondary,
    // },
}));

export default function EventCard() {
    const classes = useStyles();

    return (
        <Paper className={classes.eventCard} variant="outlined">
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <Typography
                        component="span"
                        className={classes.participantType}>
                        [Portaria]
                    </Typography>
                    <Typography
                        component="span"
                        className={classes.condoName}>
                        Condomínio Serra Alta
                    </Typography>
                </Grid>
                <Grid item xs={9} >
                    <ListItem >
                        <ListItemAvatar>
                            <Avatar className={classes.participantAvatar} variant="rounded">
                                <FaRobot/>
                            </Avatar>
                        </ListItemAvatar>
                        <Typography
                            component="span"
                            className={classes.eventDescription}>
                            Qual o apartamento e o primeiro nome do morador ?
                        </Typography>
                    </ListItem>
                </Grid>
                <Grid item xs={3} >
                    <Button variant="contained" size="small" className={classes.answerButton}>
                        Atender
                    </Button>
                    <Button variant="contained" size="small" className={classes.cancelButton}>
                        Recusar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}