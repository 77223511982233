
import React from "react";

export const MainFilterContext = React.createContext(null);
MainFilterContext.displayName = 'MainFilterContext';

/**
 * This context keeps info about the filter selected on the main page.
 * The locations are read from @see WatchedLocationsContext
 *
 */
export const MainFilterContextProvider = props => {
    const [locationsFilter, setLocationsFilter] = React.useState([]);
    const [sessionCreatedFilter, setSessionsCreatedFilter] = React.useState(new Date());

    const isSessionCreatedFilterToday = () => {
        const now = new Date();
        return now.getFullYear() === sessionCreatedFilter.getFullYear()
            && now.getMonth() === sessionCreatedFilter.getMonth()
            && now.getDate() === sessionCreatedFilter.getDate();
    };

    const contextValue = {
        locationsFilter,
        setLocationsFilter,
        sessionCreatedFilter,
        setSessionsCreatedFilter,
        isSessionCreatedFilterToday,
    };


    return (
        <MainFilterContext.Provider value={contextValue}>{props.children}</MainFilterContext.Provider>
    );

}