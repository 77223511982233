
import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function CardInfoMenu(props) {

    const {sessionId, logUrl} = props;
    const [anchorMenu, setAnchorMenu] = React.useState(null);

    const handleOpenMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        console.log('handleOpenMenu.event.currentTarget', event.currentTarget);
        setAnchorMenu(event.currentTarget);
    };

    const handleCloseMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorMenu(null);
    };

    const handleGetLogMenu = (event, logUrl) => {
        event.preventDefault();
        event.stopPropagation();
        handleCloseMenu(event);
        if (logUrl) {
            window.open(logUrl, "_blank");
        }
    }

    const handleGetSessionInfoMenu = (event, sessionId) => {
        console.log("handleGetSessionInfoMenu", sessionId);
        event.preventDefault();
        event.stopPropagation();
        window.open(`/sessionViewer/${sessionId}`, "_blank");
        handleCloseMenu(event);
    }

    return (
        <div>
            <Tooltip title="Mais opções..." style={{ float: 'right' }}>
                <IconButton size='small' aria-controls="card-menu" aria-haspopup="true"
                            onClick={handleOpenMenu}>
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id="card-menu"
                anchorEl={anchorMenu}
                keepMounted
                open={Boolean(anchorMenu)}
                onClose={handleCloseMenu}
            >
                {logUrl
                && <MenuItem onClick={(e)=> handleGetLogMenu(e, logUrl)}>Voximplant log</MenuItem>}
                <MenuItem onClick={(e)=> handleGetSessionInfoMenu(e, sessionId)}>Session info</MenuItem>
            </Menu>
        </div>
    );
}