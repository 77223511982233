import { BrowserRouter, Switch, Route } from 'react-router-dom';
import React from "react";
import Box from '@material-ui/core/Box';
import { grey } from '@material-ui/core/colors';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'; //https://material-ui-pickers.dev/
import LuxonUtils from '@date-io/luxon';

import Login from './pages/login/LoginPage';
import WelcomePage from './pages/welcome/WelcomePage';
import MainPage from './pages/main/MainPage';
import JsonSessionViewer from './pages/json-session-viewer/JsonSessionViewer';
import Header from './components/header/HeaderComponent'
import "./index.css"
import useUserToken from './hooks/use-user-token';
import {UserContextProvider} from "./contexts/user-context";
import {WatchedLocationsContextProvider} from "./contexts/watched-locations-context";

function App() {
    const {isUserValidated} = useUserToken();
    const [userAvailable, setUserAvailable] = React.useState(false);
    const userContextValue = {
        userAvailable, setUserAvailable
    };

    console.log(`Is mocked mode: ${process.env.REACT_APP_MOCK_SERVER} | userValidated=${isUserValidated()}`);

    if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
    } else {
        Notification.requestPermission();
    }

    if(!isUserValidated()) {
        return <Login/>
    } else {
        return (
            <MuiPickersUtilsProvider utils={LuxonUtils} locale="pt">
                <Box display="flex" flexDirection="column"
                     style={{
                         backgroundColor: grey[200],
                         height: "100vh"}}>
                    <UserContextProvider value={userContextValue}>
                        <WatchedLocationsContextProvider>
                            <BrowserRouter>
                                <Header/>
                                <Switch>
                                    <Route path="/" exact={true} component={WelcomePage}/>
                                    <Route path="/welcome" exact={true} component={WelcomePage}/>
                                    <Route path="/main" exact={true} component={MainPage}/>
                                    <Route path="/sessionViewer/:sessionId" exact={true} component={JsonSessionViewer}/>
                                </Switch>
                            </BrowserRouter>
                        </WatchedLocationsContextProvider>
                    </UserContextProvider>
                </Box>
            </MuiPickersUtilsProvider>
        );
    }
}

export default App;
