
export class SessionFeedback {
    constructor(id) {
        this._id = id;
        this.sessionId = '';
        this.comment = '';
        this.createdAt = null;
        this.userInfo = {
            _id: '',
            fullName: '',
        }
    }
}
