import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FaPlay, FaPause, FaDownload } from 'react-icons/fa';
import {makeStyles, withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { grey, blue } from '@material-ui/core/colors';
import FileSaver from 'file-saver'; //https://github.com/eligrey/FileSaver.js/

import { usePlayerContext } from '@cassette/hooks';

import { PlayerContextProvider } from '@cassette/core';
import { PlayerContextConsumer } from '@cassette/core'; //https://benwiley4000.github.io/cassette/styleguide/#playercontextconsumer
import { MediaProgress } from '@cassette/player';
import '@cassette/player/dist/css/cassette-player.css';

import {ParticipantType, Participant} from '../../models/doormai/participant';
import {SERVER_URL} from '../../utils/server-utils';
import useUserToken from "../../hooks/use-user-token";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    participantType: {
        fontSize: theme.typography.pxToRem(12),
    },
}));

const StyledButton = withStyles((theme) => ({
    root: {
        margin: theme.spacing(0.5),
        border: 'none',
        textTransform: 'none',
        padding: '4px 12px',
        marginTop: 8,
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:focus': {
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            backgroundColor: grey[300],
        },
    },
}))(Button);

/**
 *
 * @param props
 *      cardInfo    <SessionCardInfo>
 * @constructor
 */
export default function CardInfoPlayer(props) {
    const classes = useStyles();
    const [playlist, setPlaylist] = React.useState([]);
    const {userToken} = useUserToken();

    React.useEffect(() => {
        //console.log('useEffect',props.cardInfo.audiosChannels);
        const newPLaylist = [];
        let loopIndex = 0; //to order audios per loop, and not per participType
        let isThereAudio;
        if (props.cardInfo && props.cardInfo.audiosChannels) {
            let channelAudios;
            do {
                isThereAudio = false;
                for (let participType in props.cardInfo.audiosChannels) {
                    channelAudios = props.cardInfo.audiosChannels[participType];
                    if (channelAudios.length>loopIndex) {
                        isThereAudio = true;
                        let filename = props.cardInfo.location.name.replace(/ /g, '_');
                        if (props.cardInfo.callerTypeDescription)
                            filename += '_'+participType;
                        const sessionDate = new Date(props.cardInfo.createdAt);
                        filename += `_${sessionDate.getHours()}h${sessionDate.getMinutes()}m.mp3`;
                        newPLaylist.push(
                            {url: channelAudios[loopIndex], title: '', filename: filename, participantType: participType}
                        );
                    }
                }
                loopIndex++;
            } while (isThereAudio);
        }

        if (props.cardInfo && props.cardInfo.audioObjects) {
            let channelAudios, audioURL, filename;
            const namePrefix = props.cardInfo.location.name.replace(/ /g, '_');
            props.cardInfo.audioObjects.forEach( (audioObj, idx) => {
                const recordingDate = new Date(audioObj.recordingStartedAt);
                console.log(`recordingDate=${recordingDate} | audioObj=`, audioObj);
                filename = namePrefix+'_'+audioObj.participantType
                    + `_${recordingDate.getHours()}h${recordingDate.getMinutes()}m.mp3`;
                audioURL = SERVER_URL.REST + audioObj.audioUrl + `?Authorization=${userToken.token}`;
                newPLaylist.push(
                    {url: audioURL, title: '', filename: filename, participantType: audioObj.participantType}
                );
            });
        }
        console.log("newPLaylist", newPLaylist);
        setPlaylist(newPLaylist);
    }, []);

    const createParticipantButtons = function (paused, trackLoading,
                                               onTogglePause, activeTrackIndex, onSelectTrackIndex) {
        const participantButtons = [];
        //console.log('createParticipantButtons', paused, activeTrackIndex, playlist[activeTrackIndex]);

        let playlistIndex = -1;
        playlist.forEach( trackInfo => {
            playlistIndex++;
            participantButtons.push(
                <ParticipantButton key={playlistIndex}
                                   playlistIndex={playlistIndex}
                                   activeTrackIndex={activeTrackIndex}
                                   onSelectTrackIndex={onSelectTrackIndex}
                                   trackLoading={trackLoading}
                                   onTogglePause={onTogglePause}
                                   paused={paused}
                                   participType={trackInfo.participantType}
                                   classes={classes}/>
            );
        });
        return participantButtons;
    };

    const handleDownloadAudio = (activeTrackIndex) => {
        //console.log('handleDownloadAudio', activeTrackIndex, playlist[activeTrackIndex]);
        const {url, filename} = playlist[activeTrackIndex];
        FileSaver.saveAs(url, filename);
    };

    //console.log('CardInfoPlayer.playlist', playlist);
    if (!playlist || playlist.length<1)
        return null;
    else {
        return (
            <PlayerContextProvider playlist={playlist} autoplay={true} defaultRepeatStrategy='none'>
                <PlayerContextConsumer
                    filterList={['paused', 'onTogglePause', 'trackLoading', 'onSelectTrackIndex', 'activeTrackIndex']}>
                    {({paused, onTogglePause, trackLoading, onSelectTrackIndex, activeTrackIndex}) => {
                        return (
                            <Grid container>
                                <Grid item xs={12} align="center">
                                    <ButtonGroup
                                        size="small"
                                        aria-label="particip audio"
                                        style={{flexWrap:'wrap'}}
                                    >
                                        {createParticipantButtons(paused, trackLoading,
                                            onTogglePause, activeTrackIndex, onSelectTrackIndex)}
                                    </ButtonGroup>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <div style={{
                                        background: grey[200],
                                        borderRadius: 8,
                                        height: 30,
                                        display: 'flex'
                                    }}>
                                        <MediaProgress/>
                                        <Tooltip title="Baixe o audio para o seu computador">
                                            <IconButton size='small' onClick={()=> handleDownloadAudio(activeTrackIndex)}>
                                                <FaDownload />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </Grid>
                            </Grid>
                        )
                    }
                    }
                </PlayerContextConsumer>
            </PlayerContextProvider>
        );
    }
}

function ParticipantButton(props) {
    const {playlistIndex, activeTrackIndex, onSelectTrackIndex,
        trackLoading, onTogglePause, paused, participType, classes} = props;
    const handleParticipClick = (e) => {
        //console.log('handleParticipClick', playlistIndex);
        if (playlistIndex !== activeTrackIndex)
            onSelectTrackIndex(playlistIndex);
        if (!trackLoading)
            onTogglePause();
    };

    const isButtonSelected = activeTrackIndex === playlistIndex;
    return (
            <StyledButton onClick={handleParticipClick}>
                {(paused || !isButtonSelected)
                && <FaPlay style={{marginRight:4, color: blue[300]}}/>}
                {!paused && isButtonSelected
                && <FaPause style={{marginRight:4, color: blue[300]}}/>}
                <Typography
                    component="span"
                    className={classes.participantType}>
                    {Participant.getTypeDescription(participType)}
                </Typography>
            </StyledButton>
    );
}