
import {IntentDetectedEvent} from "./intent-detected-event";
import {ParamDetectedEvent} from "./param-detected-event";
import {SessionStatusEvent} from "./session-status-event";
import {SessionKPIEvent} from "./session-kpi-event";
import {fromJSON} from "../../utils/json-utils";

export const IntentDetectedEventType = {
    CALLER_MISUNDERSTANDING_EVENT: 'CALLER_MISUNDERSTANDING_EVENT',
    DWELLER_MISUNDERSTANDING_EVENT: 'DWELLER_MISUNDERSTANDING_EVENT',
    ANSWER_UNDERSTOOD_EVENT: 'ANSWER_UNDERSTOOD_EVENT',
};

export const ParamDetectedEventType = {
    INVALID_PARAM_DETECTED: 'INVALID_PARAM_DETECTED',
    VALID_ENTITY_DETECTED: 'VALID_ENTITY_DETECTED',
};

export function createEventObject(eventJSON) {
    if (eventJSON.type) {

    }
    switch (eventJSON.eventClassName) {
        case 'IntentDetectedEvent':
            return fromJSON(IntentDetectedEvent, eventJSON);
        case 'ParamDetectedEvent':
            return fromJSON(ParamDetectedEvent, eventJSON);
        case 'SessionStatusEvent':
            return fromJSON(SessionStatusEvent, eventJSON);
        case 'SessionKPIEvent':
            return fromJSON(SessionKPIEvent, eventJSON);
        default:
            throw new Error(`Unknown eventClassName = ${eventJSON.eventClassName}`);
    }
}

export function mustHideEventToOperator(eventType) {
    return [
            IntentDetectedEventType.ANSWER_UNDERSTOOD_EVENT,
            ParamDetectedEventType.VALID_ENTITY_DETECTED,
        ].includes(eventType);
}

// let mockedEventId = 1;
// export function getMockedCallerMisUnderstandingEvent() {
//     const result = new IntentDetectedEvent(Inte);
//     result.id = mockedEventId ++;
//     result.location = Location.fromJSON({
//         id: '5f58e0b093d0811a3c68fde6',
//         name: 'Condomínio Julio Rinaldi',
//         type: 'condominium',
//         customerId: 'Aster-SP'
//     });
//     //result.contexts.set();
//     result.responseAudio = '';
//     result.responsePossibilities = [
//         ResponsePossibility.HOME_ID_IDENTIFIED,
//         ResponsePossibility.DWELLER_NAME_IDENTIFIED,
//         ResponsePossibility.COMPANY_NAME_IDENTIFIED,
//         ResponsePossibility.DELIVERY_PRODUCT_IDENTIFIED,
//         ResponsePossibility.IS_NOT_DELIVERY,
//         ResponsePossibility.ASKED_TO_CALL_EMPLOYEE,
//         ResponsePossibility.ASKED_TO_WAIT,
//     ];
//     return result;
// }
//
// export function getMockedCallerMisunderstandingEvent(eventSubtype) {
//     return createEventObject({
//         id: mockedEventId ++,
//         type: EventTypes.CALLER_MISUNDERSTANDING_EVENT,
//         subtype: eventSubtype,
//         attendedBy: 'Mocked System',
//         status: IntentDetectedEventStatus.IN_ATTENDANCE,
//         location: {
//             id: '5f58e0b093d0811a3c68fde6',
//             name: 'Condomínio Julio Rinaldi',
//             type: 'condominium',
//             customerId: 'Aster-SP'
//         },
//         contexts: {},
//         responseAudio: {},
//         responsePossibilities: [
//             ResponsePossibility.HOME_ID_IDENTIFIED,
//             ResponsePossibility.DWELLER_NAME_IDENTIFIED,
//             ResponsePossibility.COMPANY_NAME_IDENTIFIED,
//             ResponsePossibility.DELIVERY_PRODUCT_IDENTIFIED,
//             ResponsePossibility.IS_NOT_DELIVERY,
//             ResponsePossibility.ASKED_TO_WAIT,
//             ResponsePossibility.ASKED_TO_CALL_EMPLOYEE,
//         ]
//     })
// }