
import React from "react";
import useWsServer from "../hooks/use-ws-server";
import {useListWithIdReducer, ListAction} from "../hooks/use-list-with-id-reducer";
import {NewEventNotification} from "../models/server-notifications/new-event-notification";
import {fromJSON} from "../utils/json-utils";
import {isAppMocked} from "../utils/env-utils";

export const WatchedLocationsContext = React.createContext(null);
WatchedLocationsContext.displayName = 'WatchedLocationsContext';

/**
 * This context keeps info about the locations being actively watched and wich notifications were received from server
 *
 * @param props
 *    {
 *      watchLocations      function    (OUTPUT)    Start to watch for server notifications
 *      unwatchLocations    function    (OUTPUT)    Finish to watch for server notifications
 *      errorMsg
 *    }
 * @returns {*}
 * @constructor
 */
export const WatchedLocationsContextProvider = props => {
    const watchedLocationsRef = React.useRef([]); //there's no need to render when it changes
    const {sendJson, websocketErrorMsg} = useWsServer();
    const [notifications, setNotifications] = useListWithIdReducer([]);
    const [watchedLocationsErrorMsg, setWatchedLocationsErrorMsg] = React.useState('');

    React.useEffect(() => {
        console.log(`websocketErrorMsg = ${websocketErrorMsg} | errorMsg = ${watchedLocationsErrorMsg}`);
        if (websocketErrorMsg) {
            setWatchedLocationsErrorMsg(websocketErrorMsg);
        }
    }, [websocketErrorMsg]);

    const unwatchLocations = async (locations) => {
        if (isAppMocked()) {
            locations.forEach( location => {
                const posRef = watchedLocationsRef.current.findIndex( locRef => locRef._id === location._id);
                if (posRef>=0)
                    watchedLocationsRef.current.splice(posRef, 1);
            });
            return ;
        }

        locations.forEach( location => {
            const posRef = watchedLocationsRef.current.findIndex( locRef => locRef._id === location._id);
            if (posRef>=0)
                watchedLocationsRef.current.splice(posRef, 1);
        });
        //TODO Uncomment the code below when there is interface to handle the server notifications
        // const handleUnwatchLocationsResponse = (responseMessage) => {
        //     //console.log('handleWatchLocationsResponse', responseMessage.data);
        //     if (responseMessage.success) {
        //         locations.forEach( location => {
        //             const posRef = watchedLocationsRef.current.findIndex( locRef => locRef._id === location._id);
        //             if (posRef>=0)
        //                 watchedLocationsRef.current.splice(posRef, 1);
        //         });
        //     } else {
        //         setWatchedLocationsErrorMsg(responseMessage.message);
        //     }
        // };
        //
        // if (locations && locations.length>0) {
        //     console.log(`unwatchLocations`, locations);
        //     return await sendJson({
        //         messageType: "UNWATCH_LOCATIONS_EVENTS",
        //         locationsIds: locations.map( location => location._id),
        //     }, handleUnwatchLocationsResponse);
        // }
    };

    const watchLocations = async (locations) => {
        console.log('watchLocations', locations);
        if (locations.length === watchedLocationsRef.current.length) {
            let locationsAreIgual = true, a=0;
            while (locationsAreIgual && a<locations.length) {
                locationsAreIgual = locations[a]._id === watchedLocationsRef.current[a]._id;
                a++;
            }

            console.log('watchLocations.locationsAreEqual', locationsAreIgual);
            if (locationsAreIgual)
                return;
        }

        if (isAppMocked()) {
            setNotifications({list: watchLocationsMOCK(), type: ListAction.SET_LIST});
            watchedLocationsRef.current = locations;
            return ;
        }
        watchedLocationsRef.current = locations;
        //TODO Uncomment the code below when there is interface to handle the server notifications
        // const handleWatchLocationsResponse = (responseMessage) => {
        //     console.log('handleWatchLocationsResponse', responseMessage.data);
        //     if (responseMessage.success) {
        //         watchedLocationsRef.current = locations;
        //         let notificList = responseMessage.data.map( notificJson => fromJSON(NewEventNotification, notificJson));
        //         setNotifications({list: notificList, type: ListAction.SET_LIST});
        //     } else {
        //         setWatchedLocationsErrorMsg(responseMessage.message);
        //     }
        // };
        //
        // if (locations && locations.length>0) {
        //     return await sendJson({
        //         messageType: "WATCH_LOCATIONS_EVENTS",
        //         locationsIds: locations.map( location => location._id),
        //     }, handleWatchLocationsResponse);
        // }
    };

    const contextValue = {
        watchedLocationsRef,
        watchLocations, unwatchLocations,
        watchedLocationsErrorMsg,
    }
    return (
        <WatchedLocationsContext.Provider value={contextValue}>{props.children}</WatchedLocationsContext.Provider>
    );

};

function watchLocationsMOCK() {
    return [
        {
            "type": "NEW_EVENT_NOTIFICATION",
            "location": {
                "_id": "5f58e0b093d0811a3c68fde6",
                "name": "Condomínio Julio Rinaldi",
                "customerId": "Aster-SP"
            },
            "isAboutEvent": {
                "eventClassName": "",
                "eventDescription": "",
                "type": "",
                "status": "",
                "_id": ""
            },
            "possibleActions": [
                "pickup",
                "ignore"
            ]
        }
    ]
}