import React from 'react';
import {makeStyles} from "@material-ui/core/styles/index";
import Accordion from '@material-ui/core/Accordion';
import Grid from '@material-ui/core/Grid';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { GrFormPreviousLink, GrFormNextLink } from 'react-icons/gr';
import { DatePicker } from "@material-ui/pickers";

import {WatchedLocationsContext} from "../../contexts/watched-locations-context";
import {MainFilterContext} from "../../pages/main/contexts/main-filter-context";

const useStyles = makeStyles((theme) => ({
    // root: {
    //     // width: '100%',
    //     flexGrow: 1,
    // },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'bold',
        color: '#5589FF',
    },
    // container: {
    //     display: "flex",
    //     flexDirection: "column",
    // },
}));

const ALL_LOCATIONS_SELECT_OPTION = {_id:-1, name:'Todos condomínios'};
export default function MainFilterPanel() {
    const classes = useStyles();
    const {watchedLocationsRef} = React.useContext(WatchedLocationsContext);
    const {locationsFilter, setLocationsFilter, isSessionCreatedFilterToday,
        sessionCreatedFilter, setSessionsCreatedFilter} = React.useContext(MainFilterContext);
    const [optionsToSelect, setOptionsToSelect] = React.useState([ALL_LOCATIONS_SELECT_OPTION]);
    const [dateFilterLabel, setDateFilterLabel] = React.useState('Hoje');
    const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

    React.useEffect( () => {
        const watchedLocations = watchedLocationsRef.current;
        if (watchedLocations || watchedLocations.length > 0) {
           const newOptionsToSelect = [ALL_LOCATIONS_SELECT_OPTION];
            newOptionsToSelect.push(...watchedLocations);
            setOptionsToSelect(newOptionsToSelect);
        }
    }, []);

    React.useEffect( () => {
        if (isSessionCreatedFilterToday()) {
            setDateFilterLabel('Hoje');
        } else
            setDateFilterLabel( `${String(sessionCreatedFilter.getDate()).padStart(2, '0')}/${String(sessionCreatedFilter.getMonth()+1).padStart(2, '0')}/${sessionCreatedFilter.getFullYear()}`)
    }, [sessionCreatedFilter]);

    const handleAccordionChanged = (e, expanded) => {
        if (expanded) {
        } else {
        }
    };

    const handleCondoFilterChanged = (e, newValue) => {
        console.log('handleCondoFilterChanged', newValue);
        if (newValue._id === ALL_LOCATIONS_SELECT_OPTION._id ) {
            setLocationsFilter([]);
        } else {
            setLocationsFilter([newValue]);
        }
    };

    const handlePreviousClick = () => {
        setSessionsCreatedFilter( new Date(sessionCreatedFilter.getTime() - 24*60*60*1000) );
    };

    const handleNextClick = () => {
        if (dateFilterLabel !== 'Hoje')
            setSessionsCreatedFilter( new Date(sessionCreatedFilter.getTime() + 24*60*60*1000) );
    };

    return (
        <div>
            <Accordion onChange={handleAccordionChanged}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                    id="panel-header"
                >
                    <Typography className={classes.heading}>Filtro de dados...</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <Grid container>
                        <Grid item xs={6} >
                            <Tooltip title="Filtre os dados pelo condomínio">
                                <Autocomplete
                                    id="condo-filter"
                                    options={optionsToSelect}
                                    defaultValue={ALL_LOCATIONS_SELECT_OPTION}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option && value && option._id === value._id}
                                    style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} InputProps={{...params.InputProps, disableUnderline: true}}/>}
                                    disableClearable
                                    //value={locationsFilter}
                                    onChange={handleCondoFilterChanged}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={4}>
                            <Tooltip title="Dia anterior">
                                <IconButton onClick={handlePreviousClick}>
                                    <GrFormPreviousLink />
                                </IconButton>
                            </Tooltip>
                            <Typography component="span" onClick={()=>setIsCalendarOpen(true)}>{dateFilterLabel}</Typography>
                            <DatePicker
                                open={isCalendarOpen}
                                onClose={() => setIsCalendarOpen(false)}
                                // format="d MMM yyyy"
                                value={sessionCreatedFilter}
                                onChange={(luxonDate) => setSessionsCreatedFilter(luxonDate.toJSDate())}
                                TextFieldComponent={() => null}
                                disableFuture
                            />
                            {dateFilterLabel !== 'Hoje' && <Tooltip title="Avança um dia">
                                <IconButton onClick={handleNextClick}>
                                    <GrFormNextLink />
                                </IconButton>
                            </Tooltip>}

                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}