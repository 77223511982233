
import React from "react";
import useWsServer from "../../../hooks/use-ws-server";
import useUserToken from "../../../hooks/use-user-token";
import {isAppMocked} from "../../../utils/env-utils";
import {fromJSON} from '../../../utils/json-utils';
import {WatchedLocationsContext} from "../../../contexts/watched-locations-context";
import {KPIType, SessionKPI, SessionKPIInfo} from "../../../models/session-kpi-info";
import {SessionKPIEvent} from "../../../models/server-events/session-kpi-event";

export const WatchedSessionKPIContext = React.createContext(null);
WatchedSessionKPIContext.displayName = 'WatchedSessionKPIContext';

/**
 * This context keeps info about Sessions KPI of the watched locations.
 * It will receive all data during creation, and after that, individual messages to update
 * The locations are read from @see WatchedLocationsContext
 *
 */
export const WatchedSessionKPIContextProvider = props => {
    const {watchedLocationsRef} = React.useContext(WatchedLocationsContext);
    const {getUserId} = useUserToken();
    const [watchedKPI, setWatchedKPI] = React.useState(new SessionKPIInfo(new Date()));
    const [watchedErrorMsg, setWatchedErrorMsg] = React.useState('');

    //console.log('WatchedSessionKPIContextProvider...');
    const handleServerEventArrived = (serverEvent) => {
        if (serverEvent instanceof SessionKPIEvent) {
            const newKPI = Object.assign(new SessionKPIInfo(), watchedKPI);
            let actualLocationKPI = newKPI.getLocationKPI(serverEvent.locationId);
            if (!actualLocationKPI) {
                actualLocationKPI = newKPI.addLocationKPI(serverEvent.locationId, new SessionKPI());
            }
            const newLocationKPI = serverEvent.kpiInfo.getLocationKPI(serverEvent.locationId);
            if (serverEvent.kpiType === KPIType.SESSION_KPI) {
                actualLocationKPI.totalSessions = newLocationKPI.totalSessions;
                actualLocationKPI.sessionsSuccess = newLocationKPI.sessionsSuccess;
                actualLocationKPI.sessionsFailure = newLocationKPI.sessionsFailure;
                actualLocationKPI.sessionsUnknown = newLocationKPI.sessionsUnknown;
            } else {
                actualLocationKPI.totalEvents = newLocationKPI.totalEvents;
                if (serverEvent.eventUserId && serverEvent.eventUserId === getUserId())
                    actualLocationKPI.userAnswers = newLocationKPI.userAnswers;
                actualLocationKPI.unattendedEvents = newLocationKPI.unattendedEvents;
            }
            setWatchedKPI(newKPI);
        }
    };
    const handleSocketConnectionChanged = (connectionState) => {
        console.log('kpiContext.handleSocketConnectionChanged', connectionState);
        if (connectionState === 'openedAndValidated') {
            watchSessionKPI();
        }
    };
    const {sendJson, websocketErrorMsg} = useWsServer(
        {onServerEventArrived: handleServerEventArrived, onSocketConnectionChanged: handleSocketConnectionChanged});

    React.useEffect(() => {
        //console.log(`WatchedSessionKPIContextProvider.websocketErrorMsg = ${websocketErrorMsg} | errorMsg = ${watchedErrorMsg}`);
        if (websocketErrorMsg) {
            setWatchedErrorMsg(websocketErrorMsg);
        }
    }, [websocketErrorMsg]);

    const watchSessionKPI = async (dateFilter) => {
        const watchedLocations = watchedLocationsRef.current;
        if (!watchedLocations || watchedLocations.length === 0) {
            console.log('Nenhuma localidade está sendo monitorada. Selecione as localidades!');
            setWatchedErrorMsg('Nenhuma localidade está sendo monitorada. Selecione as localidades!');
            return;
        }

        const requestJson = {
            messageType: 'WATCH_SESSIONS_KPI',
            locationsIds: watchedLocations.map( location => location._id),
            dateFilter: dateFilter,
        };
        const handleResponse = (responseMessage) => {
            //console.log('WatchedSessionsKPI.handleResponse', responseMessage.data);
            const kpiInfo = fromJSON(SessionKPIInfo, responseMessage.data);
            if (responseMessage.success) {
                setWatchedKPI( kpiInfo );
            } else {
                setWatchedErrorMsg(responseMessage.message);
            }
        };

        return await sendJson( requestJson, handleResponse);
    };

    const unwatchSessionKPI = async () => {
        const requestJson = {
            messageType: 'UNWATCH_SESSIONS_KPI',
            locationsIds: watchedLocationsRef.current.map( location => location._id),
        };
        const handleResponse = (responseMessage) => {
            //console.log('UNwatchedSessionsKPI.handleResponse', responseMessage.data);
            if (responseMessage.success) {
            } else {
                setWatchedErrorMsg(responseMessage.message);
            }
        };

        return await sendJson( requestJson, handleResponse);
    }

    const contextValue = {
        watchedKPI,
        watchedErrorMsg,
        watchSessionKPI,
        unwatchSessionKPI,
    }
    return (
        <WatchedSessionKPIContext.Provider value={contextValue}>{props.children}</WatchedSessionKPIContext.Provider>
    );

};

