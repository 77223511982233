
import {buildResponseFromJSON, buildResponseFromStatusCode} from './http-response-utils';
import {ResponseError} from '../models/error/response-error';
import {NetworkErrorResponse} from '../models/http-responses/network-error-response';
import {getUserToken} from '../hooks/use-user-token';

export const SERVER_URL = {
    REST: process.env.REACT_APP_SERVER_REST_URL,
};

/**
 *
 * @returns {Promise<HttpResponse>}
 */
export async function get(relativeUrl) {
    return await send('GET', relativeUrl);
}

/**
 *
 * @returns {Promise<HttpResponse>}
 */
export async function post(relativeUrl, bodyJson) {
    return await send('POST', relativeUrl, bodyJson);
}

export async function put(relativeUrl, bodyJson) {
    return await send('PUT', relativeUrl, bodyJson);
}

export async function deleteObj(relativeUrl, bodyJson) {
    return await send('DELETE', relativeUrl, bodyJson);
}

/**
 *
 * @param responseObject    {HttpResponse}
 * @param throwErrorIfNotSuccess
 * @returns {Object}
 */
export function getDataFromResponse(responseObject, throwErrorIfNotSuccess = true) {
    try {
        if (responseObject.success) {
            return responseObject.data;
        } else {
            if (throwErrorIfNotSuccess)
                throw new ResponseError(responseObject.message, responseObject);
            else
                return responseObject.data;
        }
    } catch (e) {
        console.log('getDataFromResponse.error', e);
        throw e;
    }
}

async function send(method, relativeUrl, bodyJson) {
    try {
        const userToken = getUserToken();
        const requestHeaders = {
            'Content-Type': 'application/json'
        };
        if (userToken && userToken.token)
            requestHeaders.Authorization = `Bearer ${userToken.token}`;

        const response = await fetch(`${SERVER_URL.REST}${relativeUrl}`, {
            method: method,
            headers: requestHeaders,
            body: JSON.stringify(bodyJson)
        });
        const resultJson = await response.json();
        //console.log('resultJson', resultJson);
        if (resultJson && resultJson.type) {
            return buildResponseFromJSON( resultJson );
        } else {
            return buildResponseFromStatusCode( response.status );
        }

    } catch (e) {
        console.log('fetch error', relativeUrl, e);
        if (e.message.includes('Failed to fetch'))
            return new NetworkErrorResponse();
        else
            throw e;
    }
}