
export const ContextFields = {
    HOME_ID: 'homeId',
    RECIPIENT_NAME: 'recipName',
    COMPANY_NAME: 'companyName',
    DELIVERY_PRODUCT: 'deliveryProduct',
    TERMINALS_OF_HOME_ID: 'terminalsOfHomeId',
    TERMINALS_OF_RECIP_NAME: 'terminalsOfRecipName',
};
export class EventContext{
    constructor() {
        this.id = null;
        this.data = {};
    }

    get(fieldName) {
        return this.data[fieldName]
    }

    set(fieldName, value) {
        this.data[fieldName] = value;
    }
}

