const {HttpResponse, HttpStatusCode} = require('./http-response')

export class ValidLoginResponse extends HttpResponse {
    constructor(login, token) {
        super(`Login successfull`, {token: token});
        this.statusCode = ValidLoginResponse.httpStatus;
        this.success = true;
    }
    static get httpStatus() {
        return HttpStatusCode.SUCCESS;
    }
}
