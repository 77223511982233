
import {fromJSON} from '../../utils/json-utils';
import {EventContext} from "./event-context";
import {SessionEvent} from "./session-event";
import {Participant} from "../doormai/participant";

export const ResponsePossibility = {
    HOME_ID_IDENTIFIED: 'home_id_identified',
    DWELLER_NAME_IDENTIFIED: 'dweller_name_identified',
    COMPANY_NAME_IDENTIFIED: 'company_name_identified',
    DELIVERY_PRODUCT_IDENTIFIED: 'delivery_product_identified',
    IS_NOT_DELIVERY: 'is_not_delivery',
    ASKED_TO_CALL_CARETAKER: 'asked_to_call_caretaker',
    ASKED_TO_CALL_MANAGER: 'asked_to_call_manager',
    ASKED_TO_WAIT: 'asked_to_wait',
    CONFIRMATION_IDENTIFIED: 'CONFIRMATION_IDENTIFIED',
    REFUSAL_IDENTIFIED: 'REFUSAL_IDENTIFIED',
    GOING_TO_LOBBY_IDENTIFIED: 'GOING_TO_LOBBY_IDENTIFIED',
};

export class IntentDetectedEvent extends SessionEvent {
    constructor() {
        super('IntentDetectedEvent');
        this.attendedBy = null; //user info of who is resolving the event
        this.attendanceStartedAt = null;
        this.infoIdentified = {};
        this.participant = new Participant();
        this.context = new EventContext();
        this.question = '';
        this.answer = {text:'', audioUrl:'', expectedIntents: [], additionalInfo: {}};
        this.responsePossibilities = []; //ResponsePossibility
        this.homeDwellersNames = [];
    }

    fromJSON(infoJSON) {
        super.fromJSON(infoJSON);
        if (infoJSON) {
            this.participant = fromJSON(Participant, infoJSON.participant);
            this.context = fromJSON(EventContext, infoJSON.context);
        }
    }

}