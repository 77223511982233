
import React from 'react';
import useWebSocket from 'react-use-websocket';

import useUserToken from "./use-user-token";
import {buildResponseFromJSON} from "../utils/http-response-utils";
import {createEventObject} from "../models/server-events/event-factory";
import {createNotificationObject} from "../models/server-notifications/notification-factory";
import {NotificationType} from "../models/server-notifications/notification-constants";
import {isAppMocked} from "../utils/env-utils";

export const WS_SERVER_URL = process.env.REACT_APP_SERVER_WS_URL;
const WS_SERVER_WELCOME_MSG = 'Welcome to DOORMOP Server. Waiting for your token validation...';

let isConnected = false; //put here to be the same for all server instances, because the ws-connection is singleton

function* idGeneratorFn() { //https://medium.com/nossa-coletividad/es6-generators-est%C3%A3o-mudando-nosso-modo-de-escrever-javascript-e99f7c79bdd7
    let requestId = 0;
    while(true)
        yield requestId++;
}
const idGenerator = idGeneratorFn();

/**
 *
 * @param options
 *      {
 *          onHttpResponseArrived, onServerEventArrived, onMessageArrived
 *          onSocketConnectionChanged   Has one parameter: openedAndValidated | closed
 *      }
 * @returns {{sendWatchLocations: sendWatchLocations, close: close}}
 */
export default function useWsServer(options) {
    const {userToken} = useUserToken();
    const responseCallbacksRef = React.useRef({});
    const [websocketErrorMsg, setWebsocketErrorMsg] = React.useState('');
    const eventUpdatedListenersRef = React.useRef([]);
    const newEventListenersRef = React.useRef([]);

    const onSocketOpen = () => {
        //console.log('socket connected', new Date());
        isConnected = true;
        setWebsocketErrorMsg('');
        if (userToken) {
            sendTokenValidation(userToken.token)
                .catch(e => onError(e));
        }
    };

    const onSocketClose = (closeEvent) => {
        //console.log('socket closed', new Date());
        isConnected = false;
        if (options && options.onSocketConnectionChanged)
            options.onSocketConnectionChanged('closed');
    };

    const onError = (messageEvent) => {
        //console.log('socket error', messageEvent);
        if (messageEvent.message) {
            setWebsocketErrorMsg(messageEvent.message);
        } else {
            //commented because the socketConnectedListenerRef will be handle this situation
            // if (messageEvent.currentTarget instanceof WebSocket)
            //     setWebsocketErrorMsg('Erro de conexão com servidor em tempo real! Tentando novamente...');
        }
    };

    const onNotificationArrived = (notificationObj) => {
        switch (notificationObj.type) {
            case NotificationType.NEW_EVENT_NOTIFICATION:
                newEventListenersRef.current.forEach( listenerFunction => {
                    listenerFunction(notificationObj)
                } );
                break;
            case NotificationType.EVENT_UPDATED_NOTIFICATION:
                eventUpdatedListenersRef.current.forEach( listenerFunction => {
                    listenerFunction(notificationObj)
                } );
                break;
            default:
                setWebsocketErrorMsg(notificationObj);
        }
    };

    const onMessage = (messageEvent) => {
        //console.log('message arrived', messageEvent.data);
        if (messageEvent.data !== WS_SERVER_WELCOME_MSG) {
            const messageJson = JSON.parse(messageEvent.data);
            let messageObj, messageType;
            try {
                messageObj = buildResponseFromJSON( messageJson );
                messageType = 'HttpResponse';
            } catch (e) {
                //maybe is not a HttpResponse... but an server Notification Object
                try {
                    messageObj = createNotificationObject( messageJson );
                    messageType = 'ServerNotification';
                }catch (e) {
                    //or a EventObject
                    messageObj = createEventObject( messageJson );
                    messageType = 'ServerEvent';
                }
            }

            //console.log(`response.requestId ${messageObj.requestId} | itHasCallback=${responseCallbacksRef.current[messageObj.requestId] !== null}`);
            if (messageObj.requestId && !responseCallbacksRef.current[messageObj.requestId]) {
                //console.log('Callback not found for requestId', messageObj.requestId, responseCallbacksRef.current);
            } else if (messageObj.requestId && responseCallbacksRef.current[messageObj.requestId]) {
                responseCallbacksRef.current[messageObj.requestId](messageObj);
                delete responseCallbacksRef.current[messageObj.requestId];
            } else {
                switch (messageType) {
                    case 'HttpResponse':
                        if (options?.onHttpResponseArrived)
                            options?.onHttpResponseArrived(messageObj);
                        //: continue; console.log('Message response without handler:', messageObj);
                        break;
                    case 'ServerNotification': onNotificationArrived(messageObj); break;
                    case 'ServerEvent':
                        if (options?.onServerEventArrived)
                            options?.onServerEventArrived(messageObj);
                        break;
                    default:
                        options?.onMessageArrived(messageObj);
                }
            }
        }
    };

    function close() {
        getWebSocket().close();
    }

    const addEventUpdatedListener = (functionListener) => {
        if (eventUpdatedListenersRef.current.indexOf( functionListener)<0 ) {
            eventUpdatedListenersRef.current = [...eventUpdatedListenersRef.current, functionListener];
        }
    };
    const removeEventUpdatedListener = (functionListener) => {
        // console.log(`removeEventUpdatedListener ${eventUpdatedListenersRef.length}`);
        let listenerIndex = eventUpdatedListenersRef.current.indexOf(functionListener);
        if (listenerIndex>=0) {
            let listenersClone = [...eventUpdatedListenersRef.current];
            listenersClone.splice(listenerIndex, 1);
            eventUpdatedListenersRef.current = listenersClone;
        }
    };

    const addNewEventListener = (functionListener) => {
        if (newEventListenersRef.current.indexOf( functionListener)<0 ) {
            newEventListenersRef.current = [...newEventListenersRef.current, functionListener];
        }
    };
    const removeNewEventListener = (functionListener) => {
        let listenerIndex = newEventListenersRef.current.indexOf(functionListener);
        if (listenerIndex>=0) {
            let listenersClone = [...newEventListenersRef.current];
            listenersClone.splice(listenerIndex, 1);
            newEventListenersRef.current = listenersClone;
        }
    };

    async function sendJson(requestJson, responseCallback) {
        try {
            if (!isConnected)
                throw new Error('Servidor não está conectado. Atualize sua página por favor!');
            const reqId = idGenerator.next().value;
            requestJson.requestId = reqId;
            //console.log(`New request ${requestJson.messageType} | ${reqId} | ${responseCallback !== null}`);
            if (responseCallback) {
                const newResponseCallback = {};
                newResponseCallback[reqId] = responseCallback;
                responseCallbacksRef.current = Object.assign(newResponseCallback, responseCallbacksRef.current);
                //console.log('Callback registered for requestId', reqId, responseCallback);
            }
            await sendJsonMessage(requestJson);

        } catch (e) {
            console.log('sendJson.error', e, requestJson);
            setWebsocketErrorMsg(e.message);
        }
    }

    async function sendTokenValidation(token) {
        const responseCallback = (messageObj) => {
            if (!messageObj.success) {
                onError(new Error(`Token do usuário não foi validado no servidor!`));
            } else {
                if (options && options.onSocketConnectionChanged)
                    options.onSocketConnectionChanged('openedAndValidated');
            }
        };
        return await sendJson({
            messageType: "TOKEN_VALIDATION",
            userToken: token,
        }, responseCallback);
    }

    const {
        // sendMessage,
        sendJsonMessage,
        // lastMessage,
        // lastJsonMessage,
        // readyState,
        getWebSocket
    } = useWebSocket(WS_SERVER_URL, {
            share: true, //all components share the same ws connection with server
            onOpen: onSocketOpen,
            onMessage: onMessage,
            onClose: onSocketClose,
            onError: onError,
            //Will attempt to reconnect on all close events, such as server shutting down
            shouldReconnect: (closeEvent) => true,
        },
        !isAppMocked() //shouldConnect
    );

    return {
        close: close,
        sendJson: sendJson,
        addEventUpdatedListener: addEventUpdatedListener,
        removeEventUpdatedListener : removeEventUpdatedListener,
        addNewEventListener: addNewEventListener,
        removeNewEventListener: removeNewEventListener,
        isConnected,
        websocketErrorMsg,
    };

};

