const {HttpResponse} = require('./http-response')

export class EntityAlreadyExistsResponse extends HttpResponse {
    constructor(msg, data) {
        super(msg, data);
        this.statusCode = EntityAlreadyExistsResponse.httpStatus;
        this.success = false;
    }

    static get httpStatus() {
        return 409;
    }
}
