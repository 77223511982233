
export function isAppMocked() {
    if (process.env.REACT_APP_MOCK_SERVER) {
        return process.env.REACT_APP_MOCK_SERVER === 'true';
    } else {
        return false;
    }
}

export function isProduction() {
    console.log('isProduction.NODE_ENV', process.env.NODE_ENV);
    return process.env.NODE_ENV === 'production';
}