//@see in project DoormaiServer class SessionInfo -> SessionStatus
//possible values of the SessionInfo.endStatus field
export const SessionStatus = {
    NOT_ENDED: 'not_ended',
    ENDED_FULLY_UNDERSTOOD: 'ended_fully_understood',
    ENDED_PARTIALLY_UNDERSTOOD: 'ended_partially_understood',
    ENDED_WITH_ERROR: 'ended_with_error',
    ENDED_TERMINAL_UNKNOWN: 'ended_terminal_unknown',
    ENDED_VISITOR_NOT_HEARD: 'ended_visitor_not_heard',
    ENDED_DWELLER_NOT_HEARD: 'ended_dweller_not_heard',
    FORWARDED_TO_OPERATOR: 'forwarded_to_operator',
    ENDED_BY_CALL_NOT_ANSWERED: 'ended_by_call_not_answered',
    ENDED_LOST_VISITOR_ANSWER: 'ended_lost_visitor_answer', //when we listen the answer on audio  but Doormai didn't
    TEST: 'test', //when it was made in lab... to ignore in statistics
};

export const SessionResultMetric = {
    FULLY_AUTOMATED: 'automacao_completa',
    EXPECTED_FORWARD: 'encaminhamento_esperado',
    OPERATOR_PROCESS_FAILURE: 'falha_processo_operador',
    DOORMAI_PROCESS_FAILURE: 'falha_processo_doormai',
    OPERATOR_SYSTEM_FAILURE: 'falha_sistema_operador',
    INTERCOM_COMMUNICATION_FAILURE: 'falha_comunicacao_interfone',
    DOORMAI_SYSTEM_FAILURE: 'falha_sistema_doormai',
    UNDERSTANDING_FAILURE: 'falha_entendimento',
    UNKNOWN: 'desconhecido',
    IS_NOT_DELIVERY: 'nao_eh_entrega',
    TEST: 'teste',
    CONFIGURATION_ERROR: 'configuracao_errada'
};
