const {HttpResponse} = require('./http-response');

export class InvalidLoginResponse extends HttpResponse {
    constructor(login) {
        super(`Invalid Login: ${login}`);
        this.statusCode = InvalidLoginResponse.httpStatus;
        this.success = false;
    }

    static get httpStatus() {
        return 401;
    }

}
