import React from 'react';
import { FiPhoneForwarded} from 'react-icons/fi';
import { BiPhoneCall} from 'react-icons/bi';
import { BsQuestion} from 'react-icons/bs';
import { AiOutlineCheck } from 'react-icons/ai'
import { MdSmsFailed } from 'react-icons/md'
import { HiOutlinePhoneMissedCall } from 'react-icons/hi'
import { makeStyles } from '@material-ui/core/styles';
import {blue, green, red, orange} from "@material-ui/core/colors/index";
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { VscError, VscMute} from 'react-icons/vsc';

import {SessionStatus, SessionResultMetric} from '../../models/doormai/session-status';

const useStyles = makeStyles((theme) => ({
    greenAvatar: {
        color: green,
        backgroundColor: green[300],
        width: 12,
        height: 12,
        padding:4,
        marginRight: 4,
    },

    blueAvatar: {
        color: blue,
        backgroundColor: blue[300],
        width: 12,
        height: 12,
        padding:4,
        marginRight: 4,
    },

    orangeAvatar: {
        color: orange,
        backgroundColor: orange[300],
        width: 12,
        height: 12,
        padding:4,
        marginRight: 4,
    },

    redAvatar: {
        color: red,
        backgroundColor: red[300],
        width: 12,
        height: 12,
        padding:4,
        marginRight: 4,
    },
    sessionDetail: {
        fontSize: theme.typography.pxToRem(12),
    },
    sessionStatusDiv: {
        display: 'flex',
        marginTop: 4,
    },
}));

export default function SessionStatusAvatar(props) {
    const classes = useStyles();
    let {sessionIcon, className, statusDescription} = getInfoFromSessionStatus(props.cardInfo, classes);
    return (
        <div className={classes.sessionStatusDiv}>
            <Avatar className={className} component="span">
                {sessionIcon}
            </Avatar>
            <Typography
                component="span"
                className={classes.sessionDetail}>
                {statusDescription}
            </Typography>
        </div>



    );

}

function getInfoFromSessionStatus(cardInfo, classes) {
    const someoneNotHeard = [
        SessionStatus.ENDED_VISITOR_NOT_HEARD, SessionStatus.ENDED_LOST_VISITOR_ANSWER,
        SessionStatus.ENDED_DWELLER_NOT_HEARD
    ].includes(cardInfo.sessionEndStatus);
    if (!cardInfo.sessionResult
        || cardInfo.sessionResult === SessionResultMetric.UNKNOWN
        || someoneNotHeard) {
        return getIconFromSessionStatus(cardInfo, classes);
    } else {
        const {resultIcon, resultClassName, resultDescription} = getIconFromSessionResult(cardInfo, classes);
        let sessionIcon = resultIcon;
        let className = resultClassName;
        let statusDescription = resultDescription;
        return {sessionIcon, className, statusDescription}
    }
}

function getIconFromSessionStatus(cardInfo, classes) {
    let sessionIcon, className, statusDescription;
    let iconInfoFromSessionResult;
    // console.log('getIconFromSessionStatus.cardInfo', cardInfo);
    switch (cardInfo.sessionEndStatus) {
        case SessionStatus.NOT_ENDED:
            sessionIcon = <BiPhoneCall/>;
            className= classes.blueAvatar;
            statusDescription = 'Em andamento';
            break;
        case SessionStatus.FORWARDED_TO_OPERATOR:
            sessionIcon = <FiPhoneForwarded/>;
            className= classes.blueAvatar;
            statusDescription = cardInfo.forwardReasonDescription || 'Encaminhado para operador';
            break;
        case SessionStatus.ENDED_FULLY_UNDERSTOOD:
            sessionIcon = <AiOutlineCheck/>;
            className= classes.greenAvatar;
            statusDescription = '???';
            break;
        case SessionStatus.ENDED_PARTIALLY_UNDERSTOOD:
            iconInfoFromSessionResult = getIconFromSessionResult(cardInfo, classes);
            sessionIcon = iconInfoFromSessionResult.resultIcon;
            className = iconInfoFromSessionResult.resultClassName;
            statusDescription = iconInfoFromSessionResult.resultDescription;
            break;
        case SessionStatus.ENDED_TERMINAL_UNKNOWN:
            sessionIcon = <BsQuestion/>; className= classes.redAvatar; break;
        case SessionStatus.ENDED_VISITOR_NOT_HEARD:
            sessionIcon = <VscMute/>; className= classes.orangeAvatar;
            statusDescription = `${cardInfo.callerTypeDescription} ficou em silêncio`;
            break;
        case SessionStatus.ENDED_DWELLER_NOT_HEARD:
            statusDescription = 'Morador ficou em silêncio';
            sessionIcon = <VscMute/>; className= classes.orangeAvatar; break;
        case SessionStatus.ENDED_BY_CALL_NOT_ANSWERED:
            statusDescription = 'Ligação não atendida';
            sessionIcon = <HiOutlinePhoneMissedCall/>; className= classes.redAvatar; break;
        case SessionStatus.ENDED_WITH_ERROR:
        case SessionStatus.ENDED_LOST_VISITOR_ANSWER:
        default:
            iconInfoFromSessionResult = getIconFromSessionResult(cardInfo, classes);
            sessionIcon = iconInfoFromSessionResult.resultIcon;
            className = iconInfoFromSessionResult.resultClassName;
            statusDescription = iconInfoFromSessionResult.resultDescription;
            // sessionIcon = <VscError/>;
            // className = classes.redAvatar;
    }
    return {sessionIcon, className, statusDescription}
}

function getIconFromSessionResult(cardInfo, classes) {
    let resultIcon, resultClassName, resultDescription;
    switch (cardInfo.sessionResult) {
        case SessionResultMetric.FULLY_AUTOMATED:
            resultDescription = cardInfo.dwellerCommandDescription || 'Sucesso';
            resultIcon = <AiOutlineCheck/>;
            resultClassName= classes.greenAvatar;
            break;
        case SessionResultMetric.IS_NOT_DELIVERY:
            resultDescription = 'Atendimento não é entrega';
            resultIcon = <AiOutlineCheck/>;
            resultClassName= classes.greenAvatar;
            break;
        case SessionResultMetric.TEST:
            resultDescription = 'Atendimento de teste';
            resultIcon = <AiOutlineCheck/>;
            resultClassName= classes.greenAvatar;
            break;
        case SessionResultMetric.EXPECTED_FORWARD:
            resultIcon = <FiPhoneForwarded/>;
            resultClassName= classes.blueAvatar;
            resultDescription = cardInfo.forwardReasonDescription || 'Encaminhado para operador';
            break;
        case SessionResultMetric.UNDERSTANDING_FAILURE:
            resultIcon = <MdSmsFailed/>;
            resultClassName= classes.redAvatar;
            resultDescription = 'Falha de entendimento';
            break;
        case SessionResultMetric.OPERATOR_PROCESS_FAILURE:
            resultDescription = 'Falha no processo com operador';
            if (cardInfo.forwardReasonDescription) {
                resultDescription = resultDescription + `: ${cardInfo.forwardReasonDescription}`;
            }
            resultIcon = <VscError/>;
            resultClassName = classes.redAvatar;
            break;
        case SessionResultMetric.OPERATOR_SYSTEM_FAILURE:
            resultDescription = 'Falha técnica (VoIP/infra) da operação';
            if (cardInfo.forwardReasonDescription) {
                resultDescription = resultDescription + `: ${cardInfo.forwardReasonDescription}`;
            }
            resultIcon = <VscError/>;
            resultClassName = classes.redAvatar;
            break;
        case SessionResultMetric.DOORMAI_PROCESS_FAILURE:
            resultDescription = 'Falha de processo Doormai';
            resultIcon = <VscError/>;
            resultClassName = classes.redAvatar;
            break;
        case SessionResultMetric.DOORMAI_SYSTEM_FAILURE:
            resultDescription = 'Falha do Doormai';
            resultIcon = <VscError/>;
            resultClassName = classes.redAvatar;
            break;
        case SessionResultMetric.INTERCOM_COMMUNICATION_FAILURE:
            resultDescription = 'Falha do interfone';
            resultIcon = <VscError/>;
            resultClassName = classes.redAvatar;
            break;
        case SessionResultMetric.CONFIGURATION_ERROR:
            resultDescription = 'Erro de configuração';
            resultIcon = <VscError/>;
            resultClassName = classes.redAvatar;
            break;
        case SessionResultMetric.UNKNOWN:
            resultDescription = 'Desconhecido';
        default:
            resultIcon = <VscError/>;
            resultClassName = classes.redAvatar;
            if (cardInfo.forwardReasonDescription) {
                resultDescription = cardInfo.forwardReasonDescription;
            }

    }
    return {resultIcon, resultClassName, resultDescription};
}