
import React from 'react';
import {useParams} from "react-router-dom";
import ReactJson from 'react-json-view'
import Box from '@material-ui/core/Box';

import useWsServer from "../../hooks/use-ws-server";

export default function JsonSessionViewer() {
    const { sessionId } = useParams(); //https://reactrouter.com/web/example/url-params
    const [sessionJson, setSessionJson] = React.useState({});

    const handleSocketConnectionChanged = (connectionState) => {
        //console.log('handleSocketConnectionChanged', connectionState);
        const requestJson = {
            messageType: 'GET_SESSION_INFO',
            sessionId: sessionId,
        };
        const handleResponse = (responseMessage) => {
            //console.log('GET_SESSION_INFO.handleResponse', responseMessage.data);
            if (responseMessage.success) {
                //console.log('SessionInfo.data', responseMessage.data);
                setSessionJson(responseMessage.data);
                //TODO clean error msg
            } else {
                //TODO show error on Header status bar
            }
        };

        if (connectionState === 'openedAndValidated') {
            sendJson(requestJson, handleResponse);
            //TODO clean error msg
        } else {
            //TODO show error msg
        }
    };
    const {sendJson} = useWsServer({onSocketConnectionChanged: handleSocketConnectionChanged} );

    return (
        <Box m={2}>
                <ReactJson src={sessionJson} name={sessionId} iconStyle="circle"
                           collapseStringsAfterLength={100}
                           displayObjectSize={false}
                           displayDataTypes={false}
                           collapsed={1}
                           quotesOnKeys={false}
                />
        </Box>
    );
}