
import {NewEventNotification} from "./new-event-notification";
import {NotificationType} from "./notification-constants";
import {EventUpdatedNotification} from "./event-updated-notification";


export function createNotificationObject(eventJSON) {
    switch (eventJSON.type) {
        case NotificationType.NEW_EVENT_NOTIFICATION:
            return NewEventNotification.fromJSON(eventJSON);
        case NotificationType.EVENT_UPDATED_NOTIFICATION:
            return EventUpdatedNotification.fromJSON(eventJSON);
        default:
            throw new Error(`Unknown event type: ${eventJSON.type}`);
    }
}
