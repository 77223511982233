import { useState, useEffect } from 'react';
import {get, getDataFromResponse} from "../utils/server-utils";
import {isAppMocked} from "../utils/env-utils";

async function getUserLocations() {
    if (isAppMocked()) {
        return await getUserLocationsMOCK();
    } else {
        try {
            const response = await get('/user/locations');
            return getDataFromResponse(response);
        } catch (e) {
            throw e;
        }
    }
}

export default function useUserLocations({errorCallback}) {
    const [userLocations, setUserLocations] = useState(null);
    useEffect( () => {
        getUserLocations()
            .then(up => {
                console.log('setUserLocations', up);
                setUserLocations(up)
            })
            .catch(e => errorCallback(e.message));
    }, []);

    return {userLocations};
}

function getUserLocationsMOCK() {
    return new Promise( (accept, refuse) => {
        setTimeout(() => {
            accept([
                {
                    "_id": "5f58e0b093d0811a3c68fde6",
                    "name": "Condomínio Julio Rinaldi",
                    "customerId": "Aster-SP"
                },
                {
                    "_id": "5fce329c67ce175f28b2299b",
                    "name": "Condomínio Costa Rica",
                    "customerId": "Citigate-SC"
                },
                {
                    "_id": "5feb36a7b4196003dc7605d7",
                    "name": "Residencial Vox Dei",
                    "customerId": "Citigate-SC"
                },
                {
                    "_id": "605a499dda72753de8cbfbc8",
                    "name": "Edifício Uirapuru",
                    "customerId": "Aster-SP"
                },
                {
                    "_id": "6037a542362c73411ce15904",
                    "name": "Condomínio May Fair",
                    "customerId": "Aster-SP"
                },
                {
                    "_id": "600839cf91f7602a683b9ec9",
                    "name": "Condomínio Villagio do Ipiranga",
                    "customerId": "Aster-SP"
                },
                    ]
            );
        }, 1000);
    })  ;

}