
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';

import DoormaiKpiPanel from "../../components/doormai-kpi-panel/DoormaiKpiPanel";
import DoormaiSessionsPanel from "../../components/doormai-sessions-panel/DoormaiSessionsPanel";
import MainFilterPanel from "../../components/main-filter-panel/MainFilterPanel";
import DoormopOpenEventsPanel from "../../components/doormop-open-events-panel/DoormopOpenEventsPanel";
import {WatchedSessionStatusContextProvider} from './contexts/watched-session-status-context';
import {WatchedSessionKPIContextProvider} from './contexts/watched-sessions-kpi-context';
import {MainFilterContextProvider} from './contexts/main-filter-context';
import {WatchedLocationsContext} from "../../contexts/watched-locations-context";

export default function MainPage() {
    const {watchedLocationsRef} = React.useContext(WatchedLocationsContext);
    const watchedLocations = watchedLocationsRef.current;
    if (!watchedLocations || watchedLocations.length === 0) {
        return (
            <Alert severity="error">Nenhuma localidade selecionada.
                <Link href='/welcome' >  Clique aqui para resolver!</Link>
            </Alert>
        )
    }

    return (
        <Box m={3}>
            <MainFilterContextProvider>
                <Grid container spacing={3} >
                    <Grid item xs={12}>
                        <MainFilterPanel/>
                    </Grid>
                    <Grid item xs={12}>
                        <WatchedSessionKPIContextProvider>
                            <DoormaiKpiPanel/>
                        </WatchedSessionKPIContextProvider>
                    </Grid>
                    {/*<Grid item xs={8}>*/}
                        {/*<DoormopOpenEventsPanel/>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <WatchedSessionStatusContextProvider>
                            <DoormaiSessionsPanel/>
                        </WatchedSessionStatusContextProvider>
                    </Grid>
                </Grid>
            </MainFilterContextProvider>
        </Box>
    )
}