import { useState } from 'react';
import jwt_decode from "jwt-decode";

const USER_TOKEN_KEY_NAME = 'userToken';

/**
 * userToken structure
 *      {
 *          token: <user token sent by server>,
 *          name: <user fullname>
 *          id: <user id>
*       }
 *
 */

export const getUserToken = ()=>  {
    const tokenString = sessionStorage.getItem(USER_TOKEN_KEY_NAME);
    if (tokenString)
        return JSON.parse(tokenString);
    else
        return null;
};

export default function useUserToken() {
    const [userToken, setUserToken] = useState(getUserToken());

    const saveUserToken = (tokenObj) => {
        if (tokenObj) {
            sessionStorage.setItem(USER_TOKEN_KEY_NAME, JSON.stringify(tokenObj));
            setUserToken(tokenObj);
        } else {
            throw new Error(`User token nulo??`);
        }
    };

    const isUserValidated = () => {
        return userToken && userToken.token;
    }

    const hasUserAdminScope = () => {
        if (!isUserValidated())
            return false;
        const decodedToken = jwt_decode(userToken.token);
        return decodedToken && decodedToken.scope === 'full:admin';
    };

    const getFirstName = () => {
        if (!userToken)
            return '';
        const name = userToken.name;
        if (name)
            return name.split(' ')[0]
        else
            return '';
    }

    const getUserId = () => {
        return userToken?.id;
    }

    const invalidateToken = () => {
        sessionStorage.removeItem(USER_TOKEN_KEY_NAME);
        setUserToken({});
        //TODO invalidate TOKEN on Server ???
    };

    return {
        setUserToken: saveUserToken,
        invalidateToken: invalidateToken,
        userToken: userToken,
        isUserValidated: isUserValidated,
        getFirstName,
        getUserId,
        hasUserAdminScope
    };
}