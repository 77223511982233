
import {post, getDataFromResponse, deleteObj, put} from "../utils/server-utils";

export default function useSessionFeedbacks({errorCallback}) {

    const save = async (sessionFeedback) => {
        try {
            const response = await post('/sessionFeedback', sessionFeedback);
            return getDataFromResponse(response);
        } catch (e) {
            console.log('sessionFeedbacks.save', e);
            errorCallback(e);
        }
    };

    const update = async (sessionFeedback) => {
        try {
            const response = await put('/sessionFeedback', sessionFeedback);
            return getDataFromResponse(response);
        } catch (e) {
            console.log('sessionFeedbacks.update', e);
            errorCallback(e);
        }
    };

    const deleteSessionFeedback = async (sessionFeedback) => {
        try {
            const response = await deleteObj('/sessionFeedback', sessionFeedback);
            return getDataFromResponse(response);
        } catch (e) {
            console.log('sessionFeedbacks.delete', e);
            errorCallback(e);
        }
    };

    return {
        saveSessionFeedback: save,
        updateSessionFeedback: update,
        deleteSessionFeedback,
    }
}