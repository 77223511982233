import { useState, useEffect } from 'react';
import {get, getDataFromResponse} from "../utils/server-utils";
import {isAppMocked} from "../utils/env-utils";

async function getUserPreferences() {
    if (isAppMocked()) {
        return await getUserPreferencesMOCK(false);
    } else {
        try {
            const response = await get('/user/preferences');
            return getDataFromResponse(response);
        } catch (e) {
            throw e;
        }
    }
}

export default function useUserPreferences({errorCallback}) {
    const [userPreferences, setUserPreferences] = useState(null);
    useEffect( () => {
        getUserPreferences()
            .then(up => {
                console.log('setUserPreferences', up);
                setUserPreferences(up)
            })
            .catch(e => errorCallback(e.message));
    }, []);

    return {userPreferences};
}

function getUserPreferencesMOCK(emptyLastWatchedLocations) {
    return new Promise( (accept, refuse) => {
        let lastWatchedLocations;
        if (emptyLastWatchedLocations)
            lastWatchedLocations = [];
        else
            lastWatchedLocations = [
                {
                    "_id": "5f58e0b093d0811a3c68fde6",
                    "name": "Condomínio Julio Rinaldi",
                    "customerId": "Aster-SP"
                },
                {
                    "_id": "5fce329c67ce175f28b2299b",
                    "name": "Condomínio Costa Rica",
                    "customerId": "Citigate-SC"
                },
                {
                    "_id": "5feb36a7b4196003dc7605d7",
                    "name": "Residencial Vox Dei",
                    "customerId": "Citigate-SC"
                }
            ];
        setTimeout(() => {
            accept({
                    "lastWatchedLocations": lastWatchedLocations,
                    "userId": "60913d09fc45f64e34e87cd1"
                });
        }, 1000);
    })  ;

}