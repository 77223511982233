
export const KPIType = {
    SESSION_KPI: 'sessionKPI',
    EVENT_KPI: 'eventKPI',
};

export class SessionKPI {
    constructor() {
        this.totalSessions = 0;
        this.sessionsSuccess = 0;
        this.sessionsFailure = 0;
        this.sessionsUnknown = 0;
        this.totalEvents = 0;
        this.userAnswers = 0;
        this.unattendedEvents = 0;
    }
}


export class SessionKPIInfo {
    constructor(dateFilter) {
        this.sessionsCreatedAt = dateFilter;
        this.locationsKPIS = {}; //Map.key = Location.id | Map.value = SessionKPI
    }

    addLocationKPI(locationId, sessionKPI) {
        this.locationsKPIS[locationId] = sessionKPI;
        return sessionKPI;
    }

    getLocationKPI(locationId) {
        return this.locationsKPIS[locationId];
    }

    /**
     *
     * @param locationFilter  Location[]
     * @returns {SessionKPI}
     */
    sumAllLocationsKPI(locationsFilter) {
        let lKPI;
        const result = new SessionKPI();
        const locationsIdsFilter = locationsFilter && locationsFilter.length > 0 ?
            locationsFilter.map( loc => loc._id ) : null;
        for (let lid in this.locationsKPIS) {
            if (!locationsIdsFilter || locationsIdsFilter.includes(lid)) {
                lKPI = this.locationsKPIS[lid];
                for (let kpiName in result) {
                    result[kpiName] += lKPI[kpiName];
                }
            }
        }
        return result;
    }

}